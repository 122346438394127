import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationExtras, Router} from '@angular/router';

import {Observable, Subject, Subscriber, BehaviorSubject, Subscription, throwError, of} from 'rxjs';
import {take, takeUntil, filter, tap, catchError} from 'rxjs/operators';

import {AuthService} from '../auth/auth.service';
import {WebsiteDesignerService} from '../application/main/website-designer/website-designer.service';
import {IFrameService} from '../core/services/iframe/iframe.service';
import {NodesService} from '../core/services/nodes/nodes.service';
import {RoutingService} from '../core/services/routing/routing.service';
import {CustomMenuStylesService} from '../core/services/styles/custom-menu/custom-menu-styles.service';
import {CustomHomePageModalService} from '../shared/services/modals/custom-home-page/custom-home-page-modal.service';
import {HomepageImageSelectModalService} from '../shared/services/modals/homepage-image-select/homepage-image-select-modal.service';
import {WebsitesService} from '../core/services/websites/websites.service';
import {EducationImageManagerService} from '../core/services/education/image-manager/education-image-manager.service';
import {PaymentSubscriptionsService} from '../core/services/payment/subscriptions/payment-subscriptions.service';

import {NodeModel} from '../core/models/nodes/node.model';
import {WebsiteModel} from '../core/models/websites/website.model';
import {MenuSetupModel} from '../core/models/styles/setup/menu/desktop/menu-setup.model';
import {AccountModel} from '../core/models/accounts/account.model';
import {SubscriptionModel} from '../core/models/payment/subscriptions/subscription.model';

import {LIBRARY_ID} from '../application/constants';

// Decorator: check if user is logged in
// then performs navigation
// else doesn't perform navigation
function needAuth(target: any, propertyName: string, descriptor: TypedPropertyDescriptor<Function>) {
  const method = descriptor.value;

  descriptor.value = function() {
    if (this.authService.isLoggedOut) {
      return;
    }

    return method.apply(this, arguments);
  };
}

// Decorator: check if there are changes in pageEditor
function needSavePageChanges(target: any, propertyName: string, descriptor: TypedPropertyDescriptor<Function>) {
  const method = descriptor.value;

  descriptor.value = function() {
    const args = arguments;

    return new Promise((resolve, reject) => {
      this.websiteDesigner.checkForPageChanges((canLeave: boolean) => {
        return canLeave ? resolve(method.apply(this, args)) : reject();
      });
    });
  };
}

@Injectable()
export class NavigationService {
  public isEducatorAdminSubject = new BehaviorSubject(false);
  public isImageManagerSubject = new BehaviorSubject(false);
  public isPageEditorSubject = new BehaviorSubject(false);
  public isBlogPostEditingSubject = new BehaviorSubject(false);

  private account: AccountModel;

  private currentSubscription: SubscriptionModel;

  private activeWebsite: WebsiteModel = null;

  private nodes: NodeModel[] = [];

  private homeNodeSubject: BehaviorSubject<NodeModel> = new BehaviorSubject<NodeModel>(null);
  private blogNodeSubject: BehaviorSubject<NodeModel> = new BehaviorSubject<NodeModel>(null);
  private contactNodeSubject: BehaviorSubject<NodeModel> = new BehaviorSubject<NodeModel>(null);

  public get isImageManager(): boolean {
    return /image-manager/i.test(window.location.pathname);
  }

  public get isPageEditor(): boolean {
    return /page-editor/i.test(window.location.pathname);
  }

  public get isDashboard(): boolean {
    return /dashboard/i.test(window.location.pathname);
  }

  public get isSettings(): boolean {
    return /settings/i.test(window.location.pathname);
  }

  private get menuSetup(): MenuSetupModel {
    return this.customMenuStylesService.setup;
  }

  constructor(private router: Router,
              private authService: AuthService,
              private websiteDesigner: WebsiteDesignerService,
              private nodesService: NodesService,
              private customMenuStylesService: CustomMenuStylesService,
              private customHomePageModalService: CustomHomePageModalService,
              private homepageImageSelectModalService: HomepageImageSelectModalService,
              private educationImageManagerService: EducationImageManagerService,
              private websitesService: WebsitesService,
              private paymentSubscriptionsService: PaymentSubscriptionsService,
              private iFrameService: IFrameService,
              private routingService: RoutingService) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;
    });

    this.websitesService.activeWebsiteSubject.subscribe((website: WebsiteModel) => {
      this.activeWebsite = website;
    });

    this.websitesService.onForceWebsiteSwitchSubject.subscribe((website: WebsiteModel) => {
      this.handleWebsiteChange(website);
    });

    this.nodesService.nodesSubject.subscribe((nodes: NodeModel[]) => {
      this.nodes = nodes;

      this.homeNodeSubject.next(this.nodes.find((node: NodeModel) => node.isHomePage));
      this.blogNodeSubject.next(this.nodes.find((node: NodeModel) => node.type === 'B'));
      this.contactNodeSubject.next(this.nodes.find((node: NodeModel) => node.type === 'M'));
    });

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      const isImageManager: boolean = /image-manager/i.test(window.location.pathname);

      if (isImageManager !== this.isImageManagerSubject.value) this.isImageManagerSubject.next(isImageManager);

      const isEducatorAdmin: boolean = /educator-admin/i.test(window.location.pathname);

      if (isEducatorAdmin !== this.isEducatorAdminSubject.value) this.isEducatorAdminSubject.next(isEducatorAdmin);

      const isPageEditor: boolean = /page-editor/i.test(window.location.pathname);

      if (isPageEditor !== this.isPageEditorSubject.value) this.isPageEditorSubject.next(isPageEditor);
    });

    this.routingService.toAdminPanelSubject.subscribe((isRedirect: boolean) => {
      if (!isRedirect) return;

      this.toAdmin();
    });
  }

  toLogin(): void {
    this.authService.toLogin();
  }

  @needAuth
  toAdmin(): void {
    const outlet = ['/app', {
      outlets: { primary: ['admin'], sidebar: ['admin', 'customers'] },
    }];

    this.router.navigate(outlet);

    this.routingService.toAdminPanelSubject.next(false);
  }

  @needAuth
  toNewTemplatesManager(): void {
    const outlet = ['/app', {
      outlets: { primary: ['templates-manager'], sidebar: null },
    }];

    this.router.navigate(outlet);
  }

  @needAuth
  toApp(): void {
    this.router.navigate(['/app']);
  }

  @needAuth
  public toHomePage(options: { sidebar?: string[], isSidebarClosed?: boolean } = {}): Subscription {
    return this.redirectToPage({
      nodeSubject: this.homeNodeSubject,
      ...options,
    }).subscribe(() => {});
  }

  @needAuth
  public toContactPage(options: { sidebar?: string[], isSidebarClosed?: boolean } = {}): Observable<boolean> {
    return this.redirectToPage({
      nodeSubject: this.contactNodeSubject,
      ...options,
    });
  }

  private redirectToPage(options: { nodeSubject: BehaviorSubject<NodeModel>, sidebar?: string[], isSidebarClosed?: boolean }): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      const unsubscribe: Subject<boolean> = new Subject<boolean>();

      options.nodeSubject.pipe(takeUntil(unsubscribe)).subscribe((node: NodeModel) => {
        if (!node) {
          observer.next(false);
          observer.complete();

          unsubscribe.next(true);
          unsubscribe.complete();

          return;
        }

        unsubscribe.next(true);
        unsubscribe.complete();

        this.toPageEditor(node, this.getOutlets(options));

        observer.next(true);
        observer.complete();
      });
    });
  }

  public toFirstPortfolio(): void {
    const portfolio: NodeModel = this.nodes.find((node: NodeModel) => node.type === 'P');

    if (!portfolio) {
      return this.toLibrary();
    }

    return this.toImageManager({
      id: portfolio.id,
      type: portfolio.type,
    });
  }

  public toImageManagerAfterWebsiteChangeObservable(websiteId: number): Observable<boolean> {
    const unsubscribe: Subject<boolean> = new Subject<boolean>();
    
    return new Observable((observer: Subscriber<boolean>) => {
      this.nodesService.nodesSubject.pipe(takeUntil(unsubscribe)).subscribe((nodes: NodeModel[]) => {
        if (!nodes || !nodes.length || nodes[0].websiteId !== websiteId) {
          return;
        }

        unsubscribe.next(true);
        unsubscribe.complete();
        
        if (!this.activeWebsite || !this.activeWebsite.isSetupCompleted) {
          observer.next(true);
          observer.complete();

          return;
        }
  
        const portfolio: NodeModel = this.nodes.find((node: NodeModel) => node.type === 'P');

        this.handlePortfolioRedirect(portfolio);

        observer.next(true);
        observer.complete();
      });
    });
  }

  private handlePortfolioRedirect(portfolio: NodeModel): void {
    return portfolio ? this.toImageManager({ id: portfolio.id, type: portfolio.type }) : this.toLibrary();
  }

  private getOutlets(options: { sidebar?: string[], isSidebarClosed?: boolean } = { sidebar: null, isSidebarClosed: true }) {
    if (options.isSidebarClosed) return { sidebar: null };

    return options.sidebar ? { sidebar: options.sidebar } : void 0;
  }

  public handleWebsiteChange(website: WebsiteModel): Subscription {
    if (!this.currentSubscription) {
      return this.toHomePage({ sidebar: ['pages'] });
    }

    if (!this.currentSubscription.isEducator) {
      return this.toHomePage({ sidebar: ['pages'] });
    }

    if (website.type === 'User') {
      this.educationImageManagerService.setActiveTab('user', { unlock: true });

      if (this.isImageManager) {
        return this.toImageManagerAfterWebsiteChangeObservable(website.id).subscribe(() => {});
      }

      return this.toHomePage({ sidebar: ['pages'] });
    }

    this.educationImageManagerService.setActiveTab('students', { unlock: true });

    if (this.isPageEditor) {
      return this.toHomePage({ sidebar: ['pages'] });
    }

    return this.toImageManagerAfterWebsiteChangeObservable(website.id).subscribe(() => {});
  }

  public openWebsiteEditor(websiteId: number): Subscription {
    const unsubscribe: Subject<boolean> = new Subject<boolean>();

    return this.nodesService.nodesSubject.pipe(takeUntil(unsubscribe)).subscribe((nodes: NodeModel[]) => {
      if (!nodes || !nodes.length || nodes[0].websiteId !== websiteId) return;

      unsubscribe.next(true);
      unsubscribe.complete();
      
      if (this.activeWebsite && this.activeWebsite.isSetupCompleted) this.toApp();
    });
  }

  public openWebsiteImageManager(websiteId: number): Subscription {
    const unsubscribe: Subject<boolean> = new Subject<boolean>();

    return this.nodesService.nodesSubject.pipe(takeUntil(unsubscribe)).subscribe((nodes: NodeModel[]) => {
      if (!nodes || !nodes.length || nodes[0].websiteId !== websiteId) return;

      unsubscribe.next(true);
      unsubscribe.complete();
      
      if (!this.activeWebsite || !this.activeWebsite.isSetupCompleted) {
        return;
      }

      this.educationImageManagerService.setActiveTab('user', { unlock: true });

      this.toFirstPortfolio();
    });
  }

  @needAuth
  public toBlogPage(isSidebarClosed: boolean = true): Subscription {
    return new Observable((observer: Subscriber<boolean>) => {
      const unsubscribe: Subject<boolean> = new Subject<boolean>();

      this.blogNodeSubject.pipe(takeUntil(unsubscribe)).subscribe((node: NodeModel) => {
        if (!node) return;

        unsubscribe.next(true);
        unsubscribe.complete();

        this.toPageEditor(node, isSidebarClosed ? { sidebar: null } : void 0);

        observer.next();
        observer.complete();
      });
    }).subscribe(() => {});
  }

  @needAuth
  @needSavePageChanges
  public toPageEditor(page, outlets = {}): void {
    if (page && page.type === 'C' && !page.isPageExists) {
      return;
    }

    this.nodesService.isSplashEnabledSubject.pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      take(1),
    ).subscribe((isSplashEnabled: boolean) => {
      const outlet = ['/app', {
        outlets: {
          primary: ['website-designer', 'page-editor'],
          ...outlets,
        },
      }];

      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'merge',
        queryParams: {
          id: page ? page.id : null,
          type: page ? page.type : null,
          isSplash: !!page && !!page.isSplash && isSplashEnabled,
        },
      };

      this.router.navigate(outlet, navigationExtras);
    });
  }

  @needAuth
  toPageEditorWithCurrentPage() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'merge',
    };

    const outlet = ['/app', 'website-designer', 'page-editor'];

    return this.router.navigate(outlet, navigationExtras);
  }

  @needAuth
  public toLibrary(): void {
    return this.toImageManager({ id: LIBRARY_ID, type: 'P' });
  }

  @needAuth
  @needSavePageChanges
  public toImageManager(page): void {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'merge',
      queryParams: {
        id: page.id,
        type: page.type,
      //  TODO(max) => handle template
      }
    };

    const outlet = ['/app', { outlets: { primary: ['image-manager'], sidebar: ['pages'] } }];

    this.router.navigate(outlet, navigationExtras);
  }

  // Navigate to page editor or to image manager
  @needAuth
  public toEditor(page): void {
    return this.isImageManager
      ? this.toImageManager(page)
      : this.toPageEditor(page);
  }

  public getCurrentPage(activatedRoute: ActivatedRoute) {
    const {id, type, isSplash} = (<any> activatedRoute.queryParams).getValue();
    const outlet = activatedRoute.snapshot.root.firstChild.firstChild.data.path;
    
    return {
      id: parseInt(id, 10),
      type,
      isSplash,
      outlet
    };
  }

  @needAuth
  toMailSettings() {
    this.router.navigate(['/app', { outlets: { primary: ['settings', 'mailing-list'], sidebar: ['settings', 'mailing-list'] } }]);

    return true;
  }

  @needAuth
  toPurchase() {
    if (this.account && this.account.isUserImported) {
      return this.toAddOns();
    }

    this.router.navigate([
      '/app',
      {
        outlets: {
          primary: [
            'settings',
            'purchase',
          ],
          sidebar: [
            'account',
            'purchase',
          ],
          'over-sidebar': null,
        },
      },
    ], {
      queryParamsHandling: 'merge',
    });

    return true;
  }

  @needAuth
  toEducationSetup() {
    this.router.navigate(['/app', { outlets: { primary: ['settings', 'education'], sidebar: ['account', 'education'], 'over-sidebar': null } }]);

    return true;
  }

  @needAuth
  toSupportHistory() {
    this.router.navigate(['/app', { outlets: { primary: ['settings', 'support'], sidebar: ['account', 'support'], 'over-sidebar': null } }]);

    return true;
  }

  @needAuth
  toBlockTemplateChangelog(blockTemplateId: number): void {
    this.router.navigate(['/app', { outlets: { primary: ['admin', 'blocks-templates', blockTemplateId] } }]);
  }

  @needAuth
  toBlocksChanges(): void {
    this.router.navigate(['/app', { outlets: { primary: ['admin', 'blocks-changes'], sidebar: ['admin', 'blocks-changes'], 'over-sidebar': null } }]);
  }

  @needAuth
  toAdminStripe(): void {
    this.router.navigate(['/app', { outlets: { primary: ['admin', 'stripe'], sidebar: ['admin', 'stripe'], 'over-sidebar': null } }]);
  }

  @needAuth
  toStatistic(): void {
    this.router.navigate(['/app', { outlets: { primary: ['dashboard'], sidebar: null, 'over-sidebar': null } }]);
  }

  @needAuth
  toWebsitesManager(): void {
    this.router.navigate(['/app', { outlets: { primary: ['websites-manager'], sidebar: null, 'over-sidebar': null } }]);
  }

  @needAuth
  toClassesManager(): void {
    this.router.navigate(['/app', { outlets: { primary: ['classes-manager'], sidebar: null, 'over-sidebar': null } }]);
  }

  @needAuth
  toAccountInfo(): void {
    this.router.navigate(['/app', { outlets: { primary: ['settings', 'login-contact'], sidebar: ['account', 'login-contact'], 'over-sidebar': null } }]);
  }

  @needAuth
  toBlogPermissionsPage(): void {
    this.router.navigate(['/app', { outlets: { primary: ['blog-permissions'], sidebar: null, 'over-sidebar': null } }]);
  }

  @needAuth
  public toCreditCards(): void {
    this.router.navigate(['/app', { outlets: { primary: ['settings', 'credit-cards'], sidebar: ['account', 'credit-cards'], 'over-sidebar': null } }]);
  }

  @needAuth
  public toAddOns(): void {
    this.router.navigate(['/app', { outlets: { primary: ['settings', 'purchase-add-ons'], sidebar: ['account', 'purchase-add-ons'], 'over-sidebar': null } }]);
  }

  @needAuth
  public toWebsiteSettings(): void {
    this.router.navigate(['/app', { outlets: { primary: ['settings', 'website'], sidebar: ['settings', 'website'], 'over-sidebar': null } }]);
  }

  @needAuth
  public toAdminEducatorTab(tab: string): void {
    this.router.navigate(['/app', { outlets: { primary: ['admin', 'education', tab], sidebar: ['admin', 'education'], 'over-sidebar': null } }]);
  }

  @needAuth
  public toGoogleAnalyticsSetup(): void {
    this.router.navigate(['/app/google-analytics/setup/welcome']);
  }
}
