import {ImageProcessModel} from '../../../../models/image-processes/image-process.model';
import {ImageAvailabilityModel} from '../availability/image-availability.model';

export class ImagePriceModel {
  public dropdownText: string = '';

  constructor(public id: number,
              public imageId: number,
              public mediumId: number,
              public processId: number,
              public availabilityId: number,
              public price: number, // cents
              public width: number,
              public height: number,
              public length: number,
              public matWidth: number,
              public matHeight: number,
              public editionNumber: string,
              public editionSize: string,
              public isEditioned: boolean,
              public isPriceDefault: boolean,
              public isProcessDefault: boolean,
              public isImageSizeDefault: boolean,
              public isMatSizeDefault: boolean,
              public isLengthDefault: boolean,
              public isEditionedDefault: boolean,
              public isAvailabilityDefault: boolean,
              public process?: ImageProcessModel,
              public availability?: ImageAvailabilityModel) {
  }

  public static clone(price: ImagePriceModel): ImagePriceModel {
    if (!price) return null;

    const newPrice = new ImagePriceModel(
      price.id,
      price.imageId,
      price.mediumId,
      price.processId,
      price.availabilityId,
      price.price,
      price.width,
      price.height,
      price.length,
      price.matWidth,
      price.matHeight,
      price.editionNumber,
      price.editionSize,
      price.isEditioned,
      price.isPriceDefault,
      price.isProcessDefault,
      price.isImageSizeDefault,
      price.isMatSizeDefault,
      price.isLengthDefault,
      price.isEditionedDefault,
      price.isAvailabilityDefault,
      ImageProcessModel.clone(price.process),
      ImageAvailabilityModel.clone(price.availability),
    );

    newPrice.dropdownText = price.dropdownText;

    return newPrice;
  }
}
