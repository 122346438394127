import {PortfolioDefaultsModel} from './portfolio-defaults.model';

export class PortfolioDefaultsRequest {
  constructor(public portfolioId?: number,
              public title?: string,
              public caption?: string,
              public process?: string,
              public processother?: string,
              public copyright?: string,
              public purchaseInfo?: string,
              public bucket?: string,

              public editioned?: boolean,
              public editionsize?: string,
              public printnumber?: string,

              public width?: string,
              public height?: string,
              public length?: string,
              public matwidth?: string,
              public matheight?: string,

              public price?: number,
              public pricing?: string,
              public inquiryLinkText?: string,
              public purchaseText?: string,
              public isPurchaseLinkVisible?: boolean,
              public availability?: string,
              public isInquireLinkVisible?: boolean,
              public isPurchaseButtonVisible?: boolean,

              public yearproduced?: string,
              public processTypeId?: number) {}

  public static normalize(res: PortfolioDefaultsRequest): PortfolioDefaultsModel {
    if (!res) return null;

    return new PortfolioDefaultsModel(
      null,
      res.title,
      res.caption,
      res.process,
      res.processother,
      res.copyright,
      res.purchaseInfo,
      res.bucket,

      res.editioned,
      res.editionsize,
      res.printnumber,

      res.width ? parseFloat(res.width) : null,
      res.height ? parseFloat(res.height) : null,
      res.length ? parseFloat(res.length) : null,
      res.matwidth ? parseFloat(res.matwidth) : null,
      res.matheight ? parseFloat(res.matheight) : null,

      res.price,
      res.pricing,
      res.inquiryLinkText,
      res.purchaseText,
      res.availability ? parseInt(res.availability, 10) : 0,
      res.isInquireLinkVisible,
      res.isPurchaseButtonVisible,

      res.yearproduced,
      res.processTypeId,
    );
  }
}
