import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

import {PublishImageButtonComponent} from './components/buttons/publish-image-button/publish-image-button.component';
import {DoughnutChartComponent} from './components/charts/doughnut/doughnut-chart.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {CheckboxSimpleComponent} from './components/checkbox-simple/checkbox-simple.component';
import {RadiobuttonComponent} from './components/radiobutton/radiobutton.component';
import {SelectComponent} from './components/select/select.component';
import {SelectWithSearchComponent} from './components/select-with-search/select-with-search.component';
import {PopupInputComponent} from './components/popup-input/popup-input.component';
import {TooltipComponent} from './components/tooltip/tooltip.component';
import {GoogleAnalyticsButtonComponent} from './components/google-analytics-button/google-analytics-button.component';

import {ErrorHandlerService} from './util/error-handler-service';

import {CanLeaveComponentGuard} from './services/guards/can-leave-component-guard.service';
import {MailGuard} from './components/mail/guard/mail-guard.service';

import {MomentPipe} from './pipes/moment.pipe';
import {SliceEndPipe} from './pipes/slice-end.pipe';
import {ImageSizePrefixPipe} from './pipes/image-size-prefix.pipe';

import {DecimalNumberDirective} from './directives/decimal-number/decimal-number.directive';
import {IntegerNumberDirective} from './directives/integer-number/integer-number.directive';

import {ModalsService} from './services/modals/modals.service';
import {SetImageModalService} from './services/modals/set-image/set-image-modal.service';
import {AddNodeModalService} from './services/modals/add-node/add-node-modal.service';
import {ImageSpecificationsModalService} from './services/modals/image-specifications/image-specifications-modal.service';
import {BlocksDeleteModalService} from './services/modals/blocks-delete/blocks-delete-modal.service';
import {DeleteNodeModalService} from './services/modals/delete-node/delete-node-modal.service';
import {DeletePortfolioModalService} from './services/modals/delete-portfolio/delete-portfolio-modal.service';
import {PrivatePagePasswordModalService} from './services/modals/private-page-password/private-page-password-modal.service';
import {PublishingStatusModalService} from './services/modals/publishing-status/publishing-status-modal.service';
import {PublishingWebsiteInfoModalService} from './services/modals/publishing-website-info/publishing-website-info-modal.service';
import {PurchaseValidationModalService} from './services/modals/purchase-validation/purchase-validation-modal.service';
import {RemoveHomepageErrorModalService} from './services/modals/remove-homepage-error/remove-homepage-error-modal.service';
import {ImageReplaceInvalidationInfoModalService} from './services/modals/image-replace-invalidation-info/image-replace-invalidation-info-modal.service';
import {ImageManagerErrorModalService} from './services/modals/image-manager-error/image-manager-error-modal.service';
import {WrongImageTypeModalService} from './services/modals/wrong-image-type/wrong-image-type-modal.service';
import {FroalaImageIsTooLargeModalService} from './services/modals/froala-image-is-too-large/froala-image-is-too-large-modal.service';
import {FroalaVideoIsNotSupportedModalService} from './services/modals/froala-video-is-not-supported/froala-video-is-not-supported-modal.service';
import {PublishingInProgressModalService} from './services/modals/publishing-in-progress/publishing-in-progress-modal.service';
import {ImagesRemoveErrorModalService} from './services/modals/images-remove-error/images-remove-error-modal.service';
import {ObjectRemoveErrorModalService} from './services/modals/object-remove-error/object-remove-error-modal.service';
import {FailedImagesHandlingModalService} from './services/modals/failed-images-handling/failed-images-handling-modal.service';
import {DirectLinkInputModalService} from './services/modals/direct-link-input/direct-link-input-modal.service';
import {DiskIsAlmostFullModalService} from './services/modals/disk-is-almost-full/disk-is-almost-full-modal.service';
import {FirefoxAudioRecordForbiddenModalService} from './services/modals/firefox-audio-record-forbidden/firefox-audio-record-forbidden-modal.service';
import {DefaultPortfolioSetupModalService} from './services/modals/default-portfolio-setup/default-portfolio-setup-modal.service';
import {DeleteExhibitionModalService} from './services/modals/delete-exhibition/delete-exhibition-modal.service';
import {CustomHomePageModalService} from './services/modals/custom-home-page/custom-home-page-modal.service';
import {CustomHomePageSetupModalService} from './services/modals/custom-home-page-setup/custom-home-page-setup-modal.service';
import {CustomSubscriptionUpgradeErrorModalService} from './services/modals/custom-subscription-upgrade-error/custom-subscription-upgrade-error-modal.service';
import {PageLockAlertModalService} from './services/modals/page-lock-alert/page-lock-alert-modal.service';
import {DashboardSupportCallService} from './services/modals/dashboard-support-call/dashboard-support-call.service';
import {OriginalHomePageSetupModalService} from './services/modals/original-home-page-setup/original-home-page-setup-modal.service';
import {HomepageImageSelectModalService} from './services/modals/homepage-image-select/homepage-image-select-modal.service';
import {TourCloseModalService} from './services/modals/tour-close/tour-close-modal.service';
import {ImageFileNameAsTitleModalService} from './services/modals/image-file-name-as-title/image-file-name-as-title-modal.service';
import {NeedToUpgradePlanModalService} from './services/modals/need-to-upgrade-plan/need-to-upgrade-plan-modal.service';
import {WebsitesLimitExceededModalService} from './services/modals/websites-limit-exceeded/websites-limit-exceeded-modal.service';
import {AddBlogModalService} from './services/modals/add-blog/add-blog-modal.service';
import {RemoveSupportRowModalService} from './services/modals/remove-support-row/remove-support-row-modal.service';
import {RevertBlockToDefaultModalService} from './services/modals/revert-block-to-default/revert-block-to-default-modal.service';
import {SelectExhibitionModalService} from './services/modals/education/select-exhibition/select-exhibition-modal.service';
import {ReviewsListModalService} from './services/modals/education/reviews-list/reviews-list-modal.service';
import {ReviewDeleteConfirmationModalService} from './services/modals/education/review-delete-confirmation/review-delete-confirmation-modal.service';
import {ReviewStudentImageModalService} from './services/modals/education/review-student-image/review-student-image-modal.service';
import {CourseEndedModalService} from './services/modals/education/course-ended/course-ended-modal.service';
import {UpgradeSubscriptionModalService} from './services/modals/upgrade-subscription/upgrade-subscription-modal.service';
import {WebsiteStatisticCollectingInfoModalService} from './services/modals/website-statistic-collecting-info/website-statistic-collecting-info-modal.service';
import {CreditCardsModalService} from './services/modals/credit-cards/credit-cards-modal.service';
import {CouponsModalService} from './services/modals/coupons/coupons-modal.service';
import {CardDeclinedModalService} from './services/modals/card-declined/card-declined-modal.service';
import {AddOnCardDeclinedModalService} from './services/modals/add-on-card-declined/add-on-card-declined-modal.service';
import {CartItemDeleteModalService} from './services/modals/cart-item-delete/cart-item-delete-modal.service';
import {ComparePlansModalService} from './services/modals/compare-plans/compare-plans-modal.service';
import {TrialEndSurveyModalService} from './services/modals/trial-end-survey/trial-end-survey-modal.service';
import {ImageManagerChangeWebsiteToPersonalModalService} from './services/modals/image-manager-change-website-to-personal/image-manager-change-website-to-personal-modal.service';
import {WebsitesIconChangeWebsiteModalService} from './services/modals/websites-icon-change-website/websites-icon-change-website-modal.service';
import {WebsiteSubscriptionSelectModalService} from './services/modals/website-subscription-select/website-subscription-select-modal.service';
import {SubscriptionsDisabledForImportedUsersModalService} from './services/modals/subscriptions-disabled-for-imported-users-modal/subscriptions-disabled-for-imported-users-modal.service';
import {SubscriptionsDisabledForCustomSubscriptionUsersModalService} from './services/modals/subscriptions-disabled-for-custom-subscription-users-modal/subscriptions-disabled-for-custom-subscription-users-modal.service';
import {AlreadySubscribedErrorModalService} from './services/modals/already-subscribed-error/already-subscribed-error-modal.service';
import {CustomSubscriptionRemoveErrorModalService} from './services/modals/custom-subscription-remove-error/custom-subscription-remove-error-modal.service';
import {ClassEndedModalService} from './services/modals/class-ended/class-ended-modal.service';
import {StudentCourseEndedModalService} from './services/modals/student-course-ended/student-course-ended-modal.service';
import {SaveOptionsModalService} from './services/modals/save-options/save-options-modal.service';
import {SaveThroughoutWebsiteOptionsModalService} from './services/modals/save-throughout-website-options/save-throughout-website-options-modal.service';
import {FullscreenImageManagerEnlargementService} from './services/fullscreen-image-manager-enlargement/fullscreen-image-manager-enlargement.service';
import {ImageEditorCropService} from './services/image-editor/crop/image-editor-crop.service';
import {ChangesMonitorService} from './services/changes-monitor/changes-monitor.service';
import {CoreService} from './services/core/core.service';
import {ImageManagerModalService} from './components/modals/image-manager-modal/image-manager-modal.service';
import {ModalComponent} from './components/modals/modal/modal.component';
import {ImageManagerChangeWebsiteToPersonalModalComponent} from './components/modals/image-manager-change-website-to-personal/image-manager-change-website-to-personal-modal.component';
import {WebsitesIconChangeWebsiteModalComponent} from './components/modals/websites-icon-change-website/websites-icon-change-website-modal.component';
import {SetImageModalComponent} from './components/modals/set-image/set-image-modal.component';
import {SuccessfullySubscribedModalComponent} from './components/modals/successfully-subscribed/successfully-subscribed-modal.component';
import {ImageSpecificationsModalComponent} from './components/modals/image-specifications-modal/image-specifications-modal.component';
import {DiskIsAlmostFullModalComponent} from './components/modals/disk-is-almost-full/disk-is-almost-full-modal.component';
import {FirefoxAudioRecordForbiddenModalComponent} from './components/modals/firefox-audio-record-forbidden/firefox-audio-record-forbidden-modal.component';
import {ImageFileNameAsTitleModalComponent} from './components/modals/image-file-name-as-title/image-file-name-as-title-modal.component';
import {NeedToUpgradePlanModalComponent} from './components/modals/need-to-upgrade-plan/need-to-upgrade-plan-modal.component';
import {PagesRearrangementWarningModalComponent} from './components/modals/pages-rearrangement-warning-modal/pages-rearrangement-warning-modal.component';
import {SaveOptionsModalComponent} from './components/modals/save-options/save-options-modal.component';
import {SaveThroughoutWebsiteOptionsModalComponent} from './components/modals/save-throughout-website-options/save-throughout-website-options-modal.component';
import {SaveRequestModalComponent} from './components/modals/save-request-modal/save-request-modal.component';
import {RemoveHomepageErrorModalComponent} from './components/modals/remove-homepage-error/remove-homepage-error-modal.component';
import {ImageReplaceInvalidationInfoModalComponent} from './components/modals/image-replace-invalidation-info/image-replace-invalidation-info-modal.component';
import {WrongImageTypeModalComponent} from './components/modals/wrong-image-type/wrong-image-type-modal.component';
import {FroalaImageIsTooLargeModalComponent} from './components/modals/froala-image-is-too-large/froala-image-is-too-large-modal.component';
import {FroalaVideoIsNotSupportedModalComponent} from './components/modals/froala-video-is-not-supported/froala-video-is-not-supported-modal.component';
import {ImagesRemoveErrorModalComponent} from './components/modals/images-remove-error/images-remove-error-modal.component';
import {ObjectRemoveErrorModalComponent} from './components/modals/object-remove-error/object-remove-error-modal.component';
import {DefaultPortfolioSetupModalComponent} from './components/modals/default-portfolio-setup-modal/default-portfolio-setup-modal.component';
import {CustomHomePageModalComponent} from './components/modals/custom-home-page/custom-home-page-modal.component';
import {CustomHomePageSetupModalComponent} from './components/modals/custom-home-page-setup/custom-home-page-setup-modal.component';
import {CustomSubscriptionUpgradeErrorModalComponent} from './components/modals/custom-subscription-upgrade-error/custom-subscription-upgrade-error-modal.component';
import {PageLockAlertModalComponent} from './components/modals/page-lock-alert/page-lock-alert-modal.component';
import {DashboardSupportCallModalComponent} from './components/modals/dashboard-support-call/dashboard-support-call-modal.component';
import {OriginalHomePageSetupModalComponent} from './components/modals/original-home-page-restore/original-home-page-setup-modal.component';
import {TourCloseModalComponent} from './components/modals/tour-close/tour-close-modal.component';
import {ViewWebsiteWarningModalComponent} from './components/modals/view-website-warning-modal/view-website-warning-modal.component';
import {AddPageModalComponent} from './components/modals/add-page-modal/add-page-modal.component';
import {PrivatePagePasswordInputModalComponent} from './components/modals/private-page-password-input-modal/private-page-password-input-modal.component';
import {PublishingInProgressModalComponent} from './components/modals/publishing-in-progress/publishing-in-progress-modal.component';
import {SelectInputModalComponent} from './components/modals/select-input-modal/select-input-modal.component';
import {TextAlertModalComponent} from './components/modals/text-alert-modal/text-alert-modal.component';
import {BlockTemplateChangelogModalComponent} from './components/modals/block-template-changelog-modal/block-template-changelog-modal.component';
import {PortfolioPickerModalComponent} from './components/modals/portfolio-picker-modal/portfolio-picker-modal.component';
import {MessageModalComponent} from './components/modals/message-modal/message-modal.component';
import {LinkInputModalComponent} from './components/modals/link-input-modal/link-input-modal.component';
import {DeleteImagesModalComponent} from './components/modals/delete-images-modal/delete-images-modal.component';
import {DeleteExhibitionModalComponent} from './components/modals/delete-exhibition/delete-exhibition-modal.component';
import {DeleteAllImagesModalComponent} from './components/modals/delete-all-images-modal/delete-all-images-modal.component';
import {FloatingModalComponent} from './components/modals/floating-modal/floating-modal.component';
import {ImageManagerModalComponent} from './components/modals/image-manager-modal/image-manager-modal.component';
import {ImageManagerErrorModalComponent} from './components/modals/image-manager-error/image-manager-error-modal.component';
import {ImageProcessesModalComponent} from './components/modals/image-processes-modal/image-processes-modal.component';
import {HomepageImageSelectModalComponent} from './components/modals/homepage-image-select/homepage-image-select-modal.component';
import {PortfolioListComponent} from './components/modals/image-manager-modal/portfolio-list/portfolio-list.component';
import {PortfolioListItemComponent} from './components/modals/image-manager-modal/portfolio-list/item/portfolio-list-item.component';
import {PortfolioImagesComponent} from './components/modals/image-manager-modal/portfolio-images/portfolio-images.component';
import {ImageManagerBottomBarComponent} from './components/modals/image-manager-modal/portfolio-images/image-manager-bottom-bar/image-manager-bottom-bar.component';
import {ImageManagerLargeViewComponent} from './components/modals/image-manager-modal/portfolio-images/image-manager-large-view/image-manager-large-view.component';
import {TextAreaToModalComponent} from './components/modals/textarea-to-modal/textarea-to-modal.component';
import {ErrorModalComponent} from './components/modals/error-modal/error-modal.component';
import {PurchaseValidationModalComponent} from './components/modals/purchase-validation/purchase-validation-modal.component';
import {UpgradeModalComponent} from './components/modals/upgrade-modal/upgrade-modal.component';
import {BugReportModalComponent} from './components/modals/bug-report/bug-report-modal.component';
import {LogoutModalComponent} from './components/modals/logout-modal/logout-modal.component';
import {BlocksDeleteModalComponent} from './components/modals/blocks-delete/blocks-delete-modal.component';
import {DeleteNodeModalComponent} from './components/modals/delete-node/delete-node-modal.component';
import {DeletePortfolioModalComponent} from './components/modals/delete-portfolio/delete-portfolio-modal.component';
import {FontsManagerModalComponent} from './components/modals/fonts-manager/fonts-manager-modal.component';
import {InfoModalComponent} from './components/modals/info/info-modal.component';
import {AppVersionChangesModalComponent} from './components/modals/app-version-changes/app-version-changes-modal.component';
import {AppVersionChangesSummaryModalComponent} from './components/modals/app-version-changes-summary/app-version-changes-summary-modal.component';
import {PublishingStatusModalComponent} from './components/modals/publishing-status/publishing-status-modal.component';
import {PublishingWebsiteInfoModalComponent} from './components/modals/publishing-website-info/publishing-website-info-modal.component';
import {FailedImagesHandlingModalComponent} from './components/modals/failed-images-handling/failed-images-handling-modal.component';
import {BlogSetupModalComponent} from './components/modals/blog-setup-modal/blog-setup-modal.component';
import {CaptchaDataComponent} from './components/modals/captcha-data/captcha-data.component';
import {ContactBlockSetupModalComponent} from './components/modals/contact-block-setup/contact-block-setup-modal.component';
import {WebsitesLimitExceededModalComponent} from './components/modals/websites-limit-exceeded/websites-limit-exceeded-modal.component';
import {AddBlogModalComponent} from './components/modals/add-blog-modal/add-blog-modal.component';
import {RemoveSupportRowModalComponent} from './components/modals/remove-support-row/remove-support-row-modal.component';
import {RevertBlockToDefaultModalComponent} from './components/modals/revert-block-to-default/revert-block-to-default-modal.component';
import {SelectExhibitionModalComponent} from './components/modals/education/select-exhibition/select-exhibition-modal.component';
import {EducationReviewsListModalComponent} from './components/modals/education/reviews-list/reviews-list-modal.component';
import {EducationDeleteReviewConfirmationModalComponent} from './components/modals/education/delete-review-confirmation/delete-review-confirmation-modal.component';
import {ReviewStudentImageModalComponent} from './components/modals/education/review-student-image/review-student-image-modal.component';
import {CourseEndedModalComponent} from './components/modals/education/course-ended/course-ended-modal.component';
import {MediaManagerModalComponent} from './components/modals/media-manager/media-manager-modal.component';
import {CreditCardsModalComponent} from './components/modals/credit-cards/credit-cards-modal.component';
import {CouponsModalComponent} from './components/modals/coupons/coupons-modal.component';
import {CardDeclinedModalComponent} from './components/modals/card-declined/card-declined-modal.component';
import {AddOnCardDeclinedModalComponent} from './components/modals/add-on-card-declined/add-on-card-declined-modal.component';
import {CartItemDeleteModalComponent} from './components/modals/cart-item-delete/cart-item-delete-modal.component';
import {ComparePlansModalComponent} from './components/modals/compare-plans/compare-plans-modal.component';
import {WebsiteStatisticCollectingInfoModalComponent} from './components/modals/website-statistic-collecting-info/website-statistic-collecting-info-modal.component';
import {TrialEndSurveyModalComponent} from './components/modals/trial-end-survey/trial-end-survey-modal.component';
import {WebsiteSubscriptionSelectModalComponent} from './components/modals/website-subscription-select/website-subscription-select-modal.component';
import {SubscriptionsDisabledForCustomSubscriptionUsersModalComponent} from './components/modals/subscriptions-disabled-for-custom-subscription-users/subscriptions-disabled-for-custom-subscription-users-modal.component';
import {SubscriptionsDisabledForImportedUsersModalComponent} from './components/modals/subscriptions-disabled-for-imported-users/subscriptions-disabled-for-imported-users-modal.component';
import {CustomSubscriptionRemoveErrorModalComponent} from './components/modals/custom-subscription-remove-error/custom-subscription-remove-error-modal.component';
import {ClassEndedModalComponent} from './components/modals/class-ended/class-ended-modal.component';
import {StudentCourseEndedModalComponent} from './components/modals/student-course-ended/student-course-ended-modal.component';
import {AudioSectionComponent} from './components/audio-section/audio-section.component';
import {SliderWithValueOnThumbComponent} from './components/sliders/slider-with-value-on-thumb/slider-with-value-on-thumb.component';
import {ThumbnailPositioningComponent} from './components/thumbnail-positioning/thumbnail-positioning.component';
import {TogglerComponent} from './components/toggler/toggler.component';
import {ValueMapperPipe} from './pipes/value-mapper';
import {HelpTooltipComponent} from './components/help-tooltip/help-tooltip.component';
import {BackButtonComponent} from './components/back-button/back-button.component';
import {BlogPermissionsComponent} from './components/blog/permissions/blog-permissions.component';
import {PermissionsRequiredComponent} from './components/permissions-required/permissions-required.component';
import {PermissionCheckerComponent} from './components/permission-checker/permission-checker.component';
import {ImageDetailsComponent} from './components/details/details.component';
import {VsxDropdownComponent} from './components/vsx-dropdown/vsx-dropdown.component';
import {MediaManagerComponent} from './components/media-manager/media-manager.component';
import {MediaManagerSidebarComponent} from './components/media-manager/sidebar/media-manager-sidebar.component';
import {DocumentManagerComponent} from './components/media-manager/document-manager/document-manager.component';
import {VideoManagerComponent} from './components/media-manager/video-manager/video-manager.component';
import {AudioManagerComponent} from './components/media-manager/audio-manager/audio-manager.component';
import {LogoManagerComponent} from './components/media-manager/logo-manager/logo-manager.component';
import {DropAreaComponent} from './components/drop-area/drop-area.component';
import {SideMenuComponent} from './components/sidemenu/sidemenu.component';
import {MultilevelListComponent} from './components/multilevel-list/multilevel-list.component';
import {MultilevelListItemComponent} from './components/multilevel-list/multilevel-list-item/multilevel-list-item.component';
import {MailComponent} from './components/mail/mail.component';
import {BrowserWarningOverlayComponent} from './components/browser-warning-overlay/browser-warning-overlay.component';
import {EditableContentTextareaComponent} from './components/editable-content-textarea/editable-content-textarea.component';
import {FontsDropdownComponent} from './components/fonts-dropdown/fonts-dropdown.component';
import {FontsManagerComponent} from './components/fonts-manager/fonts-manager.component';
import {FontsManagerFontComponent} from './components/fonts-manager/font/fonts-manager-font.component';
import {FontsManagerFontAddModalComponent} from './components/fonts-manager/font-add/fonts-manager-font-add-modal.component';
import {FontsManagerFontRemoveModalComponent} from './components/fonts-manager/font-remove/fonts-manager-font-remove-modal.component';
import {FontsManagerSpecimenComponent} from './components/fonts-manager/specimen/fonts-manager-specimen.component';
import {FontsManagerSelectComponent} from './components/fonts-manager/select/fonts-manager-select.component';
import {FontsManagerFontSizeWrapperComponent} from './components/fonts-manager/font-size-wrapper/fonts-manager-font-size-wrapper.component';
import {FontsManagerSliderComponent} from './components/fonts-manager/slider/fonts-manager-slider.component';
import {LoaderComponent} from './components/loader/loader.component';
import {CloseIconComponent} from './components/close-icon/close-icon.component';
import {RemoveIconComponent} from './components/remove-icon/remove-icon.component';
import {VsxTextInputComponent} from './components/vsx-text-input/vsx-text-input.component';
import {ExclamationIconComponent} from './components/exclamation-icon/exclamation-icon.component';
import {TooltipBoxComponent} from './components/tooltip-box/tooltip-box.component';
import {PaddingCustomizerComponent} from './components/padding-customizer/padding-customizer.component';
import {CreditCardsComponent} from './components/credit-cards/credit-cards.component';
import {CreditCardFormComponent} from './components/credit-cards/credit-card-form/credit-card-form.component';
import {CreditCardsListComponent} from './components/credit-cards/credit-cards-list/credit-cards-list.component';
import {CreditCardComponent} from './components/credit-cards/credit-cards-list/credit-card/credit-card.component';
import {UpgradeSubscriptionModalComponent} from './components/modals/subscriptions/upgrade-modal/upgrade-subscription-modal.component';
import {AlreadySubscribedErrorModalComponent} from './components/modals/subscriptions/already-subscribed-error/already-subscribed-error-modal.component';
import {CouponsComponent} from './components/coupons/coupons.component';
import {UpdateSubscriptionSummaryComponent} from './components/update-subscription-summary/update-subscription-summary.component';
import {ImageLabelComponent} from './components/image-label/image-label.component';
import {FullscreenImageManagerEnlargementComponent} from './components/fullscreen-image-manager-enlargement/fullscreen-image-manager-enlargement.component';
import {ImageEditorCropComponent} from './components/image-editor/crop/image-editor-crop.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    
    AngularSvgIconModule,
    DropzoneModule,
  ],
  declarations: [
    PublishImageButtonComponent,
    DoughnutChartComponent,
    PermissionCheckerComponent,
    CheckboxComponent,
    CheckboxSimpleComponent,
    ImageDetailsComponent,
    VsxDropdownComponent,
    PortfolioListComponent,
    PortfolioListItemComponent,
    ImageManagerBottomBarComponent,
    ImageManagerLargeViewComponent,
    PortfolioImagesComponent,
    RadiobuttonComponent,
    SelectComponent,
    SelectWithSearchComponent,
    PopupInputComponent,
    TooltipComponent,
    GoogleAnalyticsButtonComponent,
    AudioSectionComponent,
    SliderWithValueOnThumbComponent,
    ThumbnailPositioningComponent,
    TogglerComponent,
    HelpTooltipComponent,
    BackButtonComponent,
    BlogPermissionsComponent,
    PermissionsRequiredComponent,
    CloseIconComponent,
    RemoveIconComponent,
    VsxTextInputComponent,
    ModalComponent,
    ImageManagerChangeWebsiteToPersonalModalComponent,
    WebsitesIconChangeWebsiteModalComponent,
    SetImageModalComponent,
    SuccessfullySubscribedModalComponent,
    ImageSpecificationsModalComponent,
    DiskIsAlmostFullModalComponent,
    FirefoxAudioRecordForbiddenModalComponent,
    ImageFileNameAsTitleModalComponent,
    NeedToUpgradePlanModalComponent,
    PagesRearrangementWarningModalComponent,
    SaveOptionsModalComponent,
    SaveThroughoutWebsiteOptionsModalComponent,
    SaveRequestModalComponent,
    RemoveHomepageErrorModalComponent,
    ImageReplaceInvalidationInfoModalComponent,
    WrongImageTypeModalComponent,
    FroalaImageIsTooLargeModalComponent,
    FroalaVideoIsNotSupportedModalComponent,
    ImagesRemoveErrorModalComponent,
    ObjectRemoveErrorModalComponent,
    DefaultPortfolioSetupModalComponent,
    CustomHomePageModalComponent,
    CustomHomePageSetupModalComponent,
    CustomSubscriptionUpgradeErrorModalComponent,
    PageLockAlertModalComponent,
    DashboardSupportCallModalComponent,
    OriginalHomePageSetupModalComponent,
    TourCloseModalComponent,
    ViewWebsiteWarningModalComponent,
    AddPageModalComponent,
    PrivatePagePasswordInputModalComponent,
    PublishingInProgressModalComponent,
    SelectInputModalComponent,
    TextAlertModalComponent,
    BlockTemplateChangelogModalComponent,
    PortfolioPickerModalComponent,
    MessageModalComponent,
    LinkInputModalComponent,
    DeleteImagesModalComponent,
    DeleteExhibitionModalComponent,
    DeleteAllImagesModalComponent,
    FloatingModalComponent,
    ImageManagerModalComponent,
    ImageManagerErrorModalComponent,
    ImageProcessesModalComponent,
    HomepageImageSelectModalComponent,
    TextAreaToModalComponent,
    ErrorModalComponent,
    PurchaseValidationModalComponent,
    UpgradeModalComponent,
    BugReportModalComponent,
    LogoutModalComponent,
    BlocksDeleteModalComponent,
    DeleteNodeModalComponent,
    DeletePortfolioModalComponent,
    MediaManagerComponent,
    MediaManagerSidebarComponent,
    MediaManagerModalComponent,
    CreditCardsModalComponent,
    CouponsModalComponent,
    CardDeclinedModalComponent,
    AddOnCardDeclinedModalComponent,
    CartItemDeleteModalComponent,
    ComparePlansModalComponent,
    WebsiteStatisticCollectingInfoModalComponent,
    TrialEndSurveyModalComponent,
    WebsiteSubscriptionSelectModalComponent,
    SubscriptionsDisabledForCustomSubscriptionUsersModalComponent,
    SubscriptionsDisabledForImportedUsersModalComponent,
    CustomSubscriptionRemoveErrorModalComponent,
    ClassEndedModalComponent,
    StudentCourseEndedModalComponent,
    AlreadySubscribedErrorModalComponent,
    DocumentManagerComponent,
    VideoManagerComponent,
    AudioManagerComponent,
    MailComponent,
    LogoManagerComponent,
    FontsManagerModalComponent,
    InfoModalComponent,
    AppVersionChangesModalComponent,
    AppVersionChangesSummaryModalComponent,
    PublishingStatusModalComponent,
    PublishingWebsiteInfoModalComponent,
    FailedImagesHandlingModalComponent,
    BlogSetupModalComponent,
    CaptchaDataComponent,
    ContactBlockSetupModalComponent,
    WebsitesLimitExceededModalComponent,
    AddBlogModalComponent,
    RemoveSupportRowModalComponent,
    RevertBlockToDefaultModalComponent,
    SelectExhibitionModalComponent,
    EducationReviewsListModalComponent,
    EducationDeleteReviewConfirmationModalComponent,
    ReviewStudentImageModalComponent,
    CourseEndedModalComponent,
    DropAreaComponent,
    SideMenuComponent,
    MultilevelListComponent,
    MultilevelListItemComponent,
    BrowserWarningOverlayComponent,
    EditableContentTextareaComponent,
    FontsDropdownComponent,
    FontsManagerComponent,
    FontsManagerFontComponent,
    FontsManagerFontAddModalComponent,
    FontsManagerFontRemoveModalComponent,
    FontsManagerSpecimenComponent,
    FontsManagerSelectComponent,
    FontsManagerFontSizeWrapperComponent,
    FontsManagerSliderComponent,
    LoaderComponent,
    ExclamationIconComponent,
    TooltipBoxComponent,
    PaddingCustomizerComponent,
    CreditCardsComponent,
    CreditCardFormComponent,
    CreditCardsListComponent,
    CreditCardComponent,
    UpgradeSubscriptionModalComponent,
    CouponsComponent,
    UpdateSubscriptionSummaryComponent,
    ImageLabelComponent,
    FullscreenImageManagerEnlargementComponent,
    ImageEditorCropComponent,

    // Pipes
    MomentPipe,
    SliceEndPipe,
    ImageSizePrefixPipe,
    ValueMapperPipe,

    // Directives
    DecimalNumberDirective,
    IntegerNumberDirective,
  ],
  providers: [
    CanLeaveComponentGuard,
    MailGuard,

    ErrorHandlerService,
    ModalsService,
    SetImageModalService,
    AddNodeModalService,
    ImageSpecificationsModalService,
    BlocksDeleteModalService,
    DeleteNodeModalService,
    DeletePortfolioModalService,
    PrivatePagePasswordModalService,
    PublishingStatusModalService,
    PublishingWebsiteInfoModalService,
    PurchaseValidationModalService,
    RemoveHomepageErrorModalService,
    ImageReplaceInvalidationInfoModalService,
    ImageManagerErrorModalService,
    WrongImageTypeModalService,
    FroalaImageIsTooLargeModalService,
    FroalaVideoIsNotSupportedModalService,
    PublishingInProgressModalService,
    ImagesRemoveErrorModalService,
    ObjectRemoveErrorModalService,
    FailedImagesHandlingModalService,
    DirectLinkInputModalService,
    DiskIsAlmostFullModalService,
    FirefoxAudioRecordForbiddenModalService,
    DefaultPortfolioSetupModalService,
    DeleteExhibitionModalService,
    CustomHomePageModalService,
    CustomHomePageSetupModalService,
    CustomSubscriptionUpgradeErrorModalService,
    PageLockAlertModalService,
    DashboardSupportCallService,
    OriginalHomePageSetupModalService,
    HomepageImageSelectModalService,
    TourCloseModalService,
    ImageFileNameAsTitleModalService,
    NeedToUpgradePlanModalService,
    ImageManagerModalService,
    CartItemDeleteModalService,
    ComparePlansModalService,
    TrialEndSurveyModalService,
    ImageManagerChangeWebsiteToPersonalModalService,
    WebsitesIconChangeWebsiteModalService,
    WebsiteSubscriptionSelectModalService,
    SubscriptionsDisabledForImportedUsersModalService,
    SubscriptionsDisabledForCustomSubscriptionUsersModalService,
    AlreadySubscribedErrorModalService,
    CustomSubscriptionRemoveErrorModalService,
    ClassEndedModalService,
    StudentCourseEndedModalService,
    SaveOptionsModalService,
    SaveThroughoutWebsiteOptionsModalService,
    FullscreenImageManagerEnlargementService,
    ImageEditorCropService,
    ChangesMonitorService,
    CoreService,
    WebsitesLimitExceededModalService,
    AddBlogModalService,
    RemoveSupportRowModalService,
    RevertBlockToDefaultModalService,
    SelectExhibitionModalService,
    ReviewsListModalService,
    ReviewDeleteConfirmationModalService,
    ReviewStudentImageModalService,
    CourseEndedModalService,
    UpgradeSubscriptionModalService,
    WebsiteStatisticCollectingInfoModalService,
    CreditCardsModalService,
    CouponsModalService,
    CardDeclinedModalService,
    AddOnCardDeclinedModalService,
  ],
  exports: [
    CommonModule,
    FormsModule,

    PublishImageButtonComponent,
    DoughnutChartComponent,
    PermissionCheckerComponent,
    CheckboxComponent,
    CheckboxSimpleComponent,
    ImageDetailsComponent,
    PortfolioListComponent,
    PortfolioListItemComponent,
    ImageManagerBottomBarComponent,
    ImageManagerLargeViewComponent,
    PortfolioImagesComponent,
    RadiobuttonComponent,
    SelectComponent,
    SelectWithSearchComponent,
    PopupInputComponent,
    TooltipComponent,
    GoogleAnalyticsButtonComponent,
    AudioSectionComponent,
    SliderWithValueOnThumbComponent,
    ThumbnailPositioningComponent,
    TogglerComponent,
    HelpTooltipComponent,
    BackButtonComponent,
    BlogPermissionsComponent,
    PermissionsRequiredComponent,
    CloseIconComponent,
    RemoveIconComponent,
    VsxDropdownComponent,
    VsxTextInputComponent,
    ModalComponent,
    ImageManagerChangeWebsiteToPersonalModalComponent,
    WebsitesIconChangeWebsiteModalComponent,
    SetImageModalComponent,
    SuccessfullySubscribedModalComponent,
    ImageSpecificationsModalComponent,
    PagesRearrangementWarningModalComponent,
    SaveOptionsModalComponent,
    SaveThroughoutWebsiteOptionsModalComponent,
    SaveRequestModalComponent,
    RemoveHomepageErrorModalComponent,
    ImageReplaceInvalidationInfoModalComponent,
    WrongImageTypeModalComponent,
    FroalaImageIsTooLargeModalComponent,
    FroalaVideoIsNotSupportedModalComponent,
    ImagesRemoveErrorModalComponent,
    ObjectRemoveErrorModalComponent,
    DefaultPortfolioSetupModalComponent,
    CustomHomePageModalComponent,
    CustomHomePageSetupModalComponent,
    CustomSubscriptionUpgradeErrorModalComponent,
    PageLockAlertModalComponent,
    DashboardSupportCallModalComponent,
    OriginalHomePageSetupModalComponent,
    TourCloseModalComponent,
    ViewWebsiteWarningModalComponent,
    AddPageModalComponent,
    PrivatePagePasswordInputModalComponent,
    PublishingInProgressModalComponent,
    SelectInputModalComponent,
    TextAlertModalComponent,
    BlockTemplateChangelogModalComponent,
    PortfolioPickerModalComponent,
    MessageModalComponent,
    LinkInputModalComponent,
    DeleteImagesModalComponent,
    DeleteExhibitionModalComponent,
    DeleteAllImagesModalComponent,
    FloatingModalComponent,
    ImageManagerModalComponent,
    ImageManagerErrorModalComponent,
    ImageProcessesModalComponent,
    HomepageImageSelectModalComponent,
    DiskIsAlmostFullModalComponent,
    FirefoxAudioRecordForbiddenModalComponent,
    ImageFileNameAsTitleModalComponent,
    NeedToUpgradePlanModalComponent,
    TextAreaToModalComponent,
    ErrorModalComponent,
    PurchaseValidationModalComponent,
    UpgradeModalComponent,
    BugReportModalComponent,
    LogoutModalComponent,
    BlocksDeleteModalComponent,
    DeleteNodeModalComponent,
    DeletePortfolioModalComponent,
    MediaManagerModalComponent,
    CreditCardsModalComponent,
    CouponsModalComponent,
    CardDeclinedModalComponent,
    AddOnCardDeclinedModalComponent,
    CartItemDeleteModalComponent,
    ComparePlansModalComponent,
    WebsiteStatisticCollectingInfoModalComponent,
    TrialEndSurveyModalComponent,
    WebsiteSubscriptionSelectModalComponent,
    SubscriptionsDisabledForCustomSubscriptionUsersModalComponent,
    SubscriptionsDisabledForImportedUsersModalComponent,
    CustomSubscriptionRemoveErrorModalComponent,
    ClassEndedModalComponent,
    StudentCourseEndedModalComponent,
    AlreadySubscribedErrorModalComponent,
    DocumentManagerComponent,
    MailComponent,
    LogoManagerComponent,
    FontsManagerModalComponent,
    InfoModalComponent,
    AppVersionChangesModalComponent,
    AppVersionChangesSummaryModalComponent,
    PublishingStatusModalComponent,
    PublishingWebsiteInfoModalComponent,
    FailedImagesHandlingModalComponent,
    BlogSetupModalComponent,
    CaptchaDataComponent,
    ContactBlockSetupModalComponent,
    WebsitesLimitExceededModalComponent,
    AddBlogModalComponent,
    RemoveSupportRowModalComponent,
    RevertBlockToDefaultModalComponent,
    SelectExhibitionModalComponent,
    EducationReviewsListModalComponent,
    EducationDeleteReviewConfirmationModalComponent,
    ReviewStudentImageModalComponent,
    CourseEndedModalComponent,
    DropAreaComponent,
    SideMenuComponent,
    MultilevelListComponent,
    MultilevelListItemComponent,
    BrowserWarningOverlayComponent,
    EditableContentTextareaComponent,
    FontsDropdownComponent,
    FontsManagerComponent,
    FontsManagerFontComponent,
    FontsManagerFontAddModalComponent,
    FontsManagerFontRemoveModalComponent,
    FontsManagerSpecimenComponent,
    FontsManagerSelectComponent,
    FontsManagerFontSizeWrapperComponent,
    FontsManagerSliderComponent,
    LoaderComponent,
    ExclamationIconComponent,
    TooltipBoxComponent,
    PaddingCustomizerComponent,
    CreditCardsComponent,
    CreditCardFormComponent,
    CreditCardsListComponent,
    CreditCardComponent,
    UpgradeSubscriptionModalComponent,
    CouponsComponent,
    UpdateSubscriptionSummaryComponent,
    ImageLabelComponent,
    FullscreenImageManagerEnlargementComponent,
    ImageEditorCropComponent,

    // Pipes
    MomentPipe,
    SliceEndPipe,
    ImageSizePrefixPipe,
    ValueMapperPipe,

    // Directives
    DecimalNumberDirective,
    IntegerNumberDirective,
  ],

  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class SharedModule {}
