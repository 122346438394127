import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {BehaviorSubject} from 'rxjs';

import {SidebarService} from '../core/services/sidebar/sidebar.service';
import {NavigationService} from './navigation.service';
import {EducationImageManagerService} from '../core/services/education/image-manager/education-image-manager.service';
import {StudentImageManagerService} from '../core/services/education/students/image-manager/student-image-manager.service';

import {NAVIGATE_MAP, Sections, PATH_TO_KEYS, SECTIONS_TO_COLLAPSE} from '../application/sidebar-short/constants';
import {KEYS} from '../core/services/sidebar/constants';

@Injectable()
export class SidebarSectionsService {
  private _selectedSection: string;

  public isSectionsCollapsedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCustomizerCollapsedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private activeSidebarSectionsMapping: { [key: string]: boolean } = {
    [Sections.Admin]: true,
    [Sections.EducatorAdmin]: true,
    [Sections.Account]: true,
    [Sections.Settings]: true,
    [Sections.Images]: true,
    [Sections.Media]: true,
    [Sections.Templates]: true,
    [Sections.Dashboard]: true,
    [Sections.WebsitesManager]: true,
    [Sections.ClassesManager]: true,
    [Sections.Blog]: true,
  };

  private isBlogPostEditing: boolean;

  private get isCustomizerCollapsed(): boolean {
    return this.isCustomizerCollapsedSubject.value;
  }

  private get isSectionsCollapsed(): boolean {
    return this.isSectionsCollapsedSubject.value;
  }

  get selectedSection() {
    return this._selectedSection;
  }

  set selectedSection(value) {
    if (!value || SECTIONS_TO_COLLAPSE.includes(value)) {
      this.isSectionsCollapsedSubject.next(true);
    }

    if (value === Sections.Images || value === Sections.Blocks || value === Sections.Pages) {
      this.isSectionsCollapsedSubject.next(false);
    }

    this._selectedSection = value;
  }

  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    private navigationService: NavigationService,
    private educationImageManagerService: EducationImageManagerService,
    private studentImageManagerService: StudentImageManagerService,
  ) {
    this.navigationService.isBlogPostEditingSubject.subscribe((value: boolean) => {
      this.isBlogPostEditing = value;

      if (this.selectedSection === Sections.Blocks && this.isBlogPostEditing) {
        this.selectedSection = null;

        return;
      }

      if (!this.isBlogPostEditing) {
        return;
      }

      this.selectSectionCategory(KEYS.BLOG);
    });
  }

  getSectionFromRoute(route: ActivatedRoute): string {
    const primaryOutlet = this.getPrimaryOutletPath(route);

    if (primaryOutlet === 'educator-admin') return KEYS.EDUCATOR_ADMIN;
    if (primaryOutlet === 'image-manager') return KEYS.IMAGES;
    if (primaryOutlet === 'templates-manager') return KEYS.TEMPLATES;
    if (primaryOutlet === 'dashboard') return KEYS.DASHBOARD;
    if (primaryOutlet === 'media') return KEYS.MEDIA;

    const sidebarOutlet = this.getSidebarOutlet(route);
    const section = sidebarOutlet && sidebarOutlet.routeConfig.path.split('/')[0] || '';

    if (PATH_TO_KEYS[section]) {
      return PATH_TO_KEYS[section];
    }

    if (this.isBlogPostEditing) {
      return KEYS.BLOG;
    }
  }

  private getPrimaryOutletPath(route: ActivatedRoute): string {
    const outlet = route.children.find(item => item.outlet === 'primary');
    return outlet && outlet.routeConfig.path;
  }

  private getSidebarOutlet(route: ActivatedRoute) {
    return route.children.find(item => item.outlet === 'sidebar');
  }

  public selectSectionCategory(section: string): void {
    this.selectedSection = section;

    this.sidebarService.selectTab(section);

    if (section === 'BLOG') {
      this.isSectionsCollapsedSubject.next(true);
    }
  }

  selectSection(section: string) {
    if (!section) {
      this.isSectionsCollapsedSubject.next(true);
      this.isCustomizerCollapsedSubject.next(true);

      return;
    }

    if (section === Sections.Blocks && this.isBlogPostEditing) {
      return this.isCustomizerCollapsedSubject.next(true);
    }

    if (section === Sections.Blog) {
      this.selectSectionCategory(KEYS.BLOG);

      return this.navigationService.toBlogPage();
    }

    if (section === Sections.Customizer) {
      return this.isCustomizerCollapsedSubject.next(false);
    }

    if (section === Sections.Educator) {
      this.educationImageManagerService.setActiveTab('students', { lock: true, force: true });
    }

    if (section === Sections.Student) {
      this.studentImageManagerService.setActiveTab('student', { lock: true, force: true });
    }

    const { isSettings } = this.navigationService;

    if (this.isSectionsCollapsed && section !== Sections.Media) {
      this.isSectionsCollapsedSubject.next(false);

      if (isSettings && section === Sections.Account) {
        return;
      }
    }

    this.isCustomizerCollapsedSubject.next(true);

    this.navigateToSection(section);
  }

  navigateToSection(section: string): void {
    const outlet = NAVIGATE_MAP[section] && NAVIGATE_MAP[section].outlet;

    if (!outlet) return;

    if (!outlet.sidebar) {
      this.isSectionsCollapsedSubject.next(true);
    }

    this.router.navigate(
      ['/app', { outlets: { primary: outlet.primary, sidebar: outlet.sidebar, 'over-sidebar': null } }],
      {
        queryParamsHandling: 'merge',
      },
    );
  }

  public isSectionActive(section: string): boolean {
    if (this.activeSidebarSectionsMapping[section]) {
      return section === this.selectedSection;
    }

    if (section === Sections.Customizer) {
      return !this.isCustomizerCollapsed;
    }

    return !this.isSectionsCollapsed && this.isCustomizerCollapsed && section === this.selectedSection;
  }

  public clearSelection() {
    if (!this.isCustomizerCollapsed) return this.isCustomizerCollapsedSubject.next(true);

    this.isSectionsCollapsedSubject.next(true);
  }
}
