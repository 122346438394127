import {CategoryModel} from './categories.model';

import {ACCESS_KEYS, KEYS} from '../../core/services/sidebar/constants';
import {PERMISSIONS} from '../../core/services/service-permissions/constants';

const TAB = {
  ACCOUNT: {
    name: 'ACCOUNT',
    outlet: ['/app', { outlets: { primary: ['dashboard'], sidebar: null, 'over-sidebar': null } }],
  },
  WEBSITE_DESIGNER: {
    name: 'DESIGNER',
    outlet: ['/app', { outlets: { primary: ['website-designer', 'page-editor'], sidebar: 'pages', 'over-sidebar': null } }],
  },
  IMAGE_MANAGER: {
    name: 'IMAGE MANAGER',
    outlet: ['/app', { outlets: { primary: 'image-manager', sidebar: ['pages'], 'over-sidebar': null } }],
  },
  MEDIA: {
    name: 'MEDIA MANAGER',
    outlet: ['/app', { outlets: { primary: 'media', sidebar: null, 'over-sidebar': null } }],
  },
  MAIL: {
    name: 'MAIL',
    outlet: ['/app', { outlets: { primary: 'mail', sidebar: null, 'over-sidebar': null } }],
  },
  HELP: {
    name: 'HELP',
    outlet: ['/app', { outlets: { primary: 'help', sidebar: ['help'], 'over-sidebar': null } }],
  },
};

const Sections = { // Change to enum type after updating to typescript 2.6.1
  Dashboard: KEYS.DASHBOARD,
  Settings: KEYS.SETTINGS,
  Templates: KEYS.TEMPLATES,
  WebsitesManager: KEYS.WEBSITES_MANAGER,
  ClassesManager: KEYS.CLASSES_MANAGER,
  Website: KEYS.WEBSITE,
  Pages: KEYS.PAGES,
  Blocks: KEYS.BLOCKS,
  Images: KEYS.IMAGES,
  Media: KEYS.MEDIA,
  Account: KEYS.PROFILE,
  Customizer: KEYS.CUSTOMIZER,
  Mail: KEYS.MAIL,
  Blog: KEYS.BLOG,
  Admin: KEYS.ADMIN,
  EducatorAdmin: KEYS.EDUCATOR_ADMIN,
  Educator: KEYS.EDUCATOR,
  Student: KEYS.STUDENT,
  Help: KEYS.HELP,
};

const SECTIONS_TO_COLLAPSE = [
  Sections.Media,
  Sections.WebsitesManager,
  Sections.ClassesManager,
  Sections.EducatorAdmin,
];

const NAVIGATE_MAP = {
  [Sections.Blocks]: {
    outlet: { primary: ['website-designer', 'page-editor'], sidebar: ['blocks'] },
  },
  [Sections.Pages]: {
    outlet: {primary: ['website-designer', 'page-editor'], sidebar: ['pages']},
  },
  [Sections.WebsitesManager]: {
    outlet: {primary: ['websites-manager'], sidebar: null},
  },
  [Sections.ClassesManager]: {
    outlet: {primary: ['classes-manager'], sidebar: null},
  },
  [Sections.Website]: {
    outlet: {primary: ['website-designer', 'page-editor'], sidebar: ['pages']},
  },
  [Sections.Templates]: {
    outlet: { primary: ['templates-manager'], sidebar: null },
  },
  [Sections.Settings]: {
    outlet: { primary: ['settings', 'website'], sidebar: ['settings', 'website'] },
  },
  [Sections.Images]: {
    outlet: { primary: ['image-manager'], sidebar: ['pages']},
  },
  [Sections.Media]: {
    outlet: { primary: ['media'], sidebar: null },
  },
  [Sections.Dashboard]: {
    outlet: { primary: ['dashboard'], sidebar: null },
  },
  [Sections.Account]: {
    outlet: { primary: ['settings', 'login-contact'], sidebar: ['account', 'login-contact'] },
  },
  [Sections.Mail]: {
    outlet: { primary: ['mail'], sidebar: null },
  },
  [Sections.Admin]: {
    outlet: { primary: ['admin'], sidebar: ['admin', 'customers'] },
  },
  [Sections.EducatorAdmin]: {
    outlet: { primary: ['educator-admin'], sidebar: null },
  },
  [Sections.Educator]: {
    outlet: { primary: ['image-manager'], sidebar: ['pages']},
  },
  [Sections.Student]: {
    outlet: { primary: ['image-manager'], sidebar: ['pages']},
  },
  [Sections.Help]: {
    outlet: { primary: ['help'], sidebar: null },
  },
};

const PAGE_TYPE_DETAILS = {
  'P': 'Portfolio Page',
  'C': 'Category Page',
  'T': 'Text Page',
  'H': 'Home Page',
  'B': 'Blog Page',
  'E': 'Blank Page',
  'A': 'About Page',
  'M': 'Contact Page',
  'S': 'Splash Page',
};

const CATEGORIES_MAPPING: { [key: string]: string[] } = {
  [ACCESS_KEYS.FULL]: [
    KEYS.ADMIN,
    KEYS.EDUCATOR,
    KEYS.STUDENT,
    KEYS.PROFILE,
    KEYS.DASHBOARD,
    KEYS.SETTINGS,
    KEYS.TEMPLATES,
    KEYS.IMAGES,
    KEYS.MEDIA,
    KEYS.PAGES,
    KEYS.BLOCKS,
    KEYS.CUSTOMIZER,
    KEYS.MAIL,
    KEYS.BLOG,
    KEYS.HELP,
  ],
  [ACCESS_KEYS.EDUCATOR]: [
    KEYS.EDUCATOR_ADMIN,
    KEYS.IMAGES,
    KEYS.WEBSITE,
    KEYS.HELP,
  ],
  [ACCESS_KEYS.STUDENT]: [
    KEYS.IMAGES,
    KEYS.WEBSITE,
    KEYS.HELP,
  ],
};

const CATEGORIES: { [key: string]: CategoryModel } = {
  [KEYS.ADMIN]: {
    section: Sections.Admin,
    iconClass: 'fa-users',
    title: 'ADMIN',
    requiredPermissions: [
      {
        type: 'permission',
        value: PERMISSIONS.ADMIN,
      }
    ],
  },
  [KEYS.EDUCATOR_ADMIN]: {
    section: Sections.EducatorAdmin,
    iconClass: 'fa-users',
    title: 'ADMIN',
    requiredPermissionsForRender: [
      {
        type: 'permission',
        value: PERMISSIONS.EDUCATOR_ADMIN,
      },
    ],
    isForbiddenForAdmins: true,
  },
  [KEYS.EDUCATOR]: {
    section: Sections.Educator,
    iconClass: 'fa-users',
    title: 'EDUCATOR',
    requiredPermissionsForRender: [
      {
        type: 'permission',
        value: PERMISSIONS.EDUCATOR_ADMIN,
      },
    ],
    isForbiddenForAdmins: true,
  },
  [KEYS.STUDENT]: {
    section: Sections.Student,
    iconClass: 'fa-users',
    title: 'EDUCATOR',
    requiredPermissionsForRender: [
      {
        type: 'permission',
        value: PERMISSIONS.STUDENT_ADMIN,
      },
    ],
    isForbiddenForAdmins: true,
  },
  [KEYS.WEBSITE]: {
    section: Sections.Website,
    svgKey: KEYS.WEBSITE,
    title: 'WEBSITE BUILDER',
  },
  [KEYS.PROFILE]: {
    section: Sections.Account,
    iconClass: 'fa-user-circle',
    title: 'PROFILE',
  },
  [KEYS.DASHBOARD]: {
    section: Sections.Dashboard,
    iconClass: 'fa-bar-chart',
    title: 'DASHBOARD',
    isSubscriptionRequired: true,
  },
  [KEYS.SETTINGS]: {
    section: Sections.Settings,
    iconClass: 'fa-cog',
    title: 'SETTINGS',
    isSubscriptionRequired: true,
  },
  [KEYS.TEMPLATES]: {
    section: Sections.Templates,
    svgKey: KEYS.TEMPLATES,
    title: 'TEMPLATES',
    isSubscriptionRequired: true,
  },
  [KEYS.IMAGES]: {
    section: Sections.Images,
    iconClass: 'fa-picture-o',
    title: 'IMAGES',
    isSubscriptionRequired: true,
  },
  [KEYS.MEDIA]: {
    section: Sections.Media,
    svgKey: KEYS.MEDIA,
    title: 'MEDIA',
    isSubscriptionRequired: true,
  },
  [KEYS.PAGES]: {
    section: Sections.Pages,
    svgKey: KEYS.PAGES,
    title: 'PAGES',
    isSubscriptionRequired: true,
  },
  [KEYS.BLOCKS]: {
    section: Sections.Blocks,
    svgKey: KEYS.BLOCKS,
    title: 'BLOCKS',
    isSubscriptionRequired: true,
  },
  [KEYS.CUSTOMIZER]: {
    section: Sections.Customizer,
    svgKey: KEYS.CUSTOMIZER,
    title: 'CUSTOMIZER',
    isSubscriptionRequired: true,
  },
  [KEYS.MAIL]: {
    section: Sections.Mail,
    svgKey: KEYS.MAIL,
    title: 'MAIL',
    requiredPermissions: [
      {
        type: 'permission',
        value: PERMISSIONS.MAILING_LIST,
      },
    ],
    isSubscriptionRequired: true,
  },
  [KEYS.BLOG]: {
    section: Sections.Blog,
    iconClass: 'fa-commenting',
    title: 'BLOG',
    requiredPermissions: [
      {
        type: 'permission',
        value: PERMISSIONS.BLOG,
      },
    ],
    isSubscriptionRequired: true,
  },
  [KEYS.HELP]: {
    section: Sections.Help,
    svgKey: KEYS.HELP,
    title: 'HELP',
    link: 'https://help.visualserver.com/',
  },
};

const PATH_TO_KEYS = {
  'admin': KEYS.ADMIN,
  'educator-admin': KEYS.EDUCATOR_ADMIN,
  'account': KEYS.PROFILE,
  'dashboard': KEYS.DASHBOARD,
  'settings': KEYS.SETTINGS,
  'templates': KEYS.TEMPLATES,
  'image-manager': KEYS.IMAGES,
  'pages': KEYS.PAGES,
  'blocks': KEYS.BLOCKS,
  'help': KEYS.HELP,
};

const TOUR_KEY = 'SIDEBAR';

export {
  NAVIGATE_MAP,
  SECTIONS_TO_COLLAPSE,
  TAB,
  PAGE_TYPE_DETAILS,
  Sections,
  ACCESS_KEYS,
  CATEGORIES_MAPPING,
  CATEGORIES,
  PATH_TO_KEYS,
  TOUR_KEY,
};
