
import {distinctUntilChanged, debounceTime, catchError, tap, finalize} from 'rxjs/operators';
import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

import {combineLatest, Subscription, Subject, Observable, throwError} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import {BagService} from '../../../bag.service';
import {PagesService} from '../../../application/sidebar-short/sidebar/pages/pages.service';
import {ImageManagerService} from '../../../application/main/image-manager/image-manager.service';
import {ImagesAvailabilitiesService} from '../../../core/services/image-manager/availabilities/availabilities.service';
import {ImagesService} from '../../../services/images.service';
import {ImagesCounterService} from '../../../core/services/image-manager/counters/images-counter.service';
import {ImageProcessesService} from '../../../services/image-processes.service';
import {MeasureUnitsService} from '../../../core/services/converters/measure-units/measure-units.service';
import {AuthService} from '../../../auth/auth.service';
import {ModalsService} from '../../services/modals/modals.service';
import {ImagesHttpService} from '../../../core/services/interaction/http/images/images-http.service';
import {CurrenciesService} from '../../../core/services/currencies/currencies.service';
import {ImageDetailsService} from '../../../core/services/image-manager/image-details/image-details.service';
import {PortfolioDefaultsService} from '../../../core/services/image-manager/portfolio-defaults/portfolio-defaults.service';
import {ImagesBucketService} from '../../../core/services/images/bucket/images-bucket.service';
import {UtilsService} from '../../../core/services/utils/utils.service';
import {IFrameRoutingService} from '../../../core/services/iframe/routing/iframe-routing.service';
import {WebsitesService} from '../../../core/services/websites/websites.service';
import {TextAlertModalService} from '../../../services/text-alert-modal.service';
import {PaymentSubscriptionsService} from '../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {WebsitesHttpService} from '../../../core/services/interaction/http/websites/websites-http.service';
import {WebsiteTourService} from '../../../core/services/website-tour/website-tour.service';
import {ImageManagerModalService} from '../modals/image-manager-modal/image-manager-modal.service';
import {PermissionsService} from '../../../core/services/service-permissions/permissions/permissions.service';
import {EducationImageManagerService} from '../../../core/services/education/image-manager/education-image-manager.service';
import {EducationImagesService} from '../../../core/services/education/teachers/institutions/classes/websites/exhibitions/images/education-images.service';
import {EducationExhibitionsImagesCounterService} from '../../../core/services/education/image-manager/exhibitions-counters/education-exhibitions-images-counter.service';
import {EducationWebsitesService} from '../../../core/services/education/teachers/institutions/classes/websites/education-websites.service';
import {StudentPortfoliosService} from '../../../core/services/education/students/websites/portfolios/student-portfolios.service';
import {StudentImageManagerService} from '../../../core/services/education/students/image-manager/student-image-manager.service';
import {CoreImageManagerService} from '../../../core/services/image-manager/core-image-manager.service';

import {ImageProcessModel} from '../../../models/image-processes/image-process.model';
import {AccountModel} from '../../../core/models/accounts/account.model';
import {ImageModel} from '../../../core/models/images/image.model';
import {ImagePriceModel} from '../../../core/models/images/price/image-price.model';
import {MeasureUnitModel} from '../../../core/models/measure-unit/measure-unit.model';
import {CurrencyModel} from '../../../core/models/currency/currency.model';
import {ImageProcessTypeModel} from '../../../models/image-processes/image-process-type.model';
import {ImageAvailabilityModel} from '../../../core/models/images/availability/image-availability.model';
import {ImagePriceDto} from '../../../core/models/images/price/image-price.dto';
import {PortfolioDefaultsModel} from '../../../core/models/images/default/portfolio-defaults.model';
import {SelectOption} from '../../../core/models/select/option/option.model';
import {RadiobuttonModel} from '../../../core/models/radiobutton/radiobutton.model';
import {NodeModel} from '../../../core/models/nodes/node.model';
import {WebsiteModel} from '../../../core/models/websites/website.model';
import {EducationStudentPortfolioModel} from '../../../core/models/education/portfolios/education-student-portfolio.model';
import {ImageLabelDto} from '../../../core/models/images/image-label/image-label.dto';
import {EducationExhibitionPortfolioModel} from '../../../core/models/education/portfolios/education-exhibition-portfolio.model';
import {PortfolioDefaultsRequest} from '../../../core/models/images/default/portfolio-defaults.request';
import {StudentImageLabelModel} from '../../models/image-manager/student-image-label/student-image-label.model';
import {SubscriptionModel} from '../../../core/models/payment/subscriptions/subscription.model';
import {ImageManagerUserTypeModel} from '../../../core/models/image-manager/user-type/user-type.model';
import {IPermissionData} from '../../../core/models/permission/i-permission-data';
import { SelectedPageModel } from '../../../core/models/selected-page/selected-page.model';

import {LIBRARY_ID} from '../../../application/constants';
import {KEY_CODES} from '../../../core/services/keys/constants';
import {AUTOSAVE_DELAY, OPTIONS_TEXTS, TOUR_KEY} from './constants';
import {KEYS} from '../../../core/services/website-tour/constants';
import {PERMISSIONS} from '../../../core/services/service-permissions/constants';
import {EducatorImageManagerTabs} from '../../../core/services/education/image-manager/constants';
import {StudentImageManagerTabs} from '../../../core/services/education/students/image-manager/constants';

import {AppAnimations} from '../../../app-animations';

@Component({
  selector: 'app-image-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ImageDetailsComponent implements OnInit, OnDestroy {
  @Input() images: Array<any>;
  @Input() isInModal: boolean;

  @ViewChild('form') form: NgForm;
  @ViewChild('formd') formDefaults: NgForm;

  TABS = {
    IMAGE_LABEL: 'image-label',
    PORTFOLIO_DEFAULTS: 'portfolio-defaults'
  };

  public isLibrary: boolean = false;

  public portfolioId: number;

  public isDefaultImageLabelEnabled: boolean;
  bucket: string;
  openedTreeNode: number;
  collapsed = false;
  panel: { properties: boolean, ownership: boolean, pricing: boolean } = {
    properties: true,
    ownership: false,
    pricing: false,
  };
  tab = this.TABS.IMAGE_LABEL;

  public isPageBlocked: boolean = false;

  public newProcessName: string = '';

  public pageId: number;

  // data models
  default: any;

  private dimensionsProps: string[] = [
    'width',
    'height',
    'length',
    'matwidth',
    'matheight',
  ];

  private payPalEmail: string = '';

  private labelAutosaveTimeout;
  private defaultsAutosaveTimeout;

  public account: AccountModel = null;
  public website: WebsiteModel = null;
  public exhibitionWebsite: WebsiteModel = null;

  public isLabelAutosaveShown = false;
  public isDefaultsAutosaveShown = false;

  private saveDefaultsSubject: Subject<PortfolioDefaultsRequest> = new Subject<PortfolioDefaultsRequest>();
  private saveDetailsSubject: Subject<ImageLabelDto> = new Subject<ImageLabelDto>();

  public editionRadiobuttonsKeys = {
    notEditioned: 'not editioned',
    editioned: 'editioned',
  };
  public editionRadiobuttons = [
    {
      key: this.editionRadiobuttonsKeys.notEditioned,
      label: 'Not Editioned',
      value: false,
    },
    {
      key: this.editionRadiobuttonsKeys.editioned,
      label: 'Editioned',
      value: true,
    },
  ];

  public purchaseLinkVisibilityRadiobuttons: RadiobuttonModel[] = [
    new RadiobuttonModel('yes', 'Yes', true),
    new RadiobuttonModel('no', 'No', false),
  ];

  public error: string = '';
  public processAddError: string = '';

  public imageSize: string = '';
  public fileSize: string = '';

  public activeStudentImageManagerTab: StudentImageManagerTabs;
  public activeImageManagerTab: EducatorImageManagerTabs;
  public isStudentsImageManagerTab: boolean = false;
  public isExhibitionsImageManagerTab: boolean = false;
  public isUserImageManagerTab: boolean = false;

  public isNewPrice: boolean = false;
  public isSinglePriceAndEmpty: boolean = false;
  public imageData: ImageModel = null;

  public pricesOptions: SelectOption[] = [];
  public currentPrice: ImagePriceModel = null;
  public currentPriceOriginal: ImagePriceModel = null;
  public currentPriceOption: SelectOption = null;

  public mediums: ImageProcessTypeModel[] = [];
  public mediumsOptions: SelectOption[] = [];
  public currentMedium: ImageProcessTypeModel = null;
  public currentMediumOption: SelectOption = null;

  public processes: ImageProcessModel[] = [];
  public processesOptions: SelectOption[] = [];
  public currentProcess: ImageProcessModel = null;
  public currentProcessOption: SelectOption = null;

  public isInProgress: boolean = false;

  public measureUnits: MeasureUnitModel[] = [];
  public measureUnit: MeasureUnitModel = null;
  public measureUnitSymbol: string;
  public measureUnitSymbolLong: string;

  public currency: CurrencyModel = null;
  public currencySymbol: string;

  public allProcesses: ImageProcessModel[] = [];

  public availabilities: ImageAvailabilityModel[] = [];
  public availabilitiesOptions: SelectOption[] = [];
  public currentAvailability: ImageAvailabilityModel = null;
  public currentAvailabilityOption: SelectOption = null;

  public studentImageLabel: StudentImageLabelModel;

  public isImageLabelTogglerBlocked: boolean = false;
  public isSimpleImageLabel: boolean = true;
  public isSimpleImageLabelTogglerVisible: boolean = false;

  public isRegularUser: boolean = false;
  public isEducatorImageManager: boolean = false;
  public isStudentImageManager: boolean = false;

  private selectedPage: SelectedPageModel;

  private selectedExhibition: EducationExhibitionPortfolioModel;

  private userData: ImageManagerUserTypeModel;

  private lastChangeTimeStamp;

  private isStudent: boolean;
  private isEducator: boolean;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  
  private studentImageLabelSaveHandlers = {
    'student': this.saveImageLabelForStudent.bind(this),
  }

  private educatorImageLabelSaveHandlers = {
    'exhibitions': this.saveImageLabelForExhibition.bind(this),
    'user': this.saveImageLabelForUser.bind(this),
  };

  private portfolioDefaultsSaveHandlers = {
    'exhibitions': this.savePortfolioDefaultsForExhibition.bind(this),
    'user': this.savePortfolioDefaultsForUser.bind(this),
  };

  private addPriceHandlers = {
    'exhibitions': this.addExhibitionImagePrice.bind(this),
    'user': this.addUserImagePrice.bind(this),
  };

  private updatePriceHandlers = {
    'exhibitions': this.updateExhibitionImagePrice.bind(this),
    'user': this.updateUserImagePrice.bind(this),
  };

  private deletePriceHandlers = {
    'exhibitions': this.deleteExhibitionImagePrice.bind(this),
    'user': this.deleteUserImagePrice.bind(this),
  };

  private publishImageHandlers = {
    'exhibitions': this.publishExhibitionImage.bind(this),
    'user': this.publishUserImage.bind(this),
  };

  private simpleImageLabelToggleHandlers = {
    'exhibitions': this.exhibitionWebsiteSimpleImageLabelToggle.bind(this),
    'user': this.userWebsiteSimpleImageLabelToggle.bind(this),
  };

  private afterSimpleImageLabelToggleHandlers = {
    'exhibitions': this.afterExhibitionWebsiteSimpleImageLabelToggle.bind(this),
    'user': this.afterUserWebsiteSimpleImageLabelToggle.bind(this),
  };

  public get activeDefaultEditionKey() {
    const button = this.editionRadiobuttons.find(button => button.value === this.default.editioned);
    return button ? button.key : void 0;
  }

  public get isStudentTab(): boolean {
    return this.isStudentImageManager && this.tabKey === 'student';
  }

  public get isStudentsTab(): boolean {
    return this.isEducatorImageManager && this.tabKey === 'students';
  }

  public get isExhibitionsTab(): boolean {
    return this.isEducatorImageManager && this.tabKey === 'exhibitions';
  }

  public get isUserTab(): boolean {
    return this.tabKey === 'user';
  }

  private get tabKey(): EducatorImageManagerTabs | StudentImageManagerTabs {
    if (this.isEducatorImageManager || this.isStudentImageManager) {
      return this.userData.tab;
    }

    return 'user';
  }

  public get isImageLabelVisible(): boolean {
    return this.imageData && this.imageData.isInfoVisible;
  }

  public get isDefaultPurchaseRadiobuttonsDisabled(): boolean {
    const availability = parseInt(this.default.availability);
    return availability !== 0 && availability !== 4;
  }

  public get LIBRARY_ID(): number {
    return LIBRARY_ID;
  }

  public get isDetails(): boolean {
    return this.tab === this.TABS.IMAGE_LABEL;
  }

  public get isAdmin(): boolean {
    return this.account && this.account.isAdmin;
  }

  public get isMorePricesAvailable(): boolean {
    if (this.isAdmin) return true;

    const imagePricesLimit = this.imagePricesLimit;

    return imagePricesLimit && this.imageData && this.imageData.prices && this.imageData.prices.length < imagePricesLimit;
  }

  public get imagePricesLimit(): number {
    return this.account && this.account.limits ? this.account.limits.imagesPrices : null;
  }

  public get isECommerce(): boolean {
    return this.account && (this.account.isAdmin || this.payPalEmail && this.account.isECommerce);
  }

  public get isSimpleLabelTogglerAccessible(): boolean {
    if (this.isEducator) {
      return !this.isStudentsImageManagerTab;
    }

    if (this.isStudent) {
      return !this.isStudentTab;
    }

    return true;
  }

  private get newPrice(): ImagePriceModel {
    return new ImagePriceModel(
      null,
      this.imageData.id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    );
  }

  private get priceModel(): ImagePriceModel {
    if (!this.default || !this.currentPriceOriginal) return null;

    return new ImagePriceModel(
      this.currentPriceOriginal.id,
      this.currentPriceOriginal.imageId,
      this.currentPriceOriginal.isProcessDefault  && !this.isLibrary ? this.default.processTypeId : this.currentPriceOriginal.mediumId,
      this.currentPriceOriginal.isProcessDefault && !this.isLibrary ? this.getDefaultProcessId() : this.currentPriceOriginal.processId,
      this.currentPriceOriginal.isAvailabilityDefault && !this.isLibrary ? this.default.availability : this.currentPriceOriginal.availabilityId,
      this.currentPriceOriginal.isPriceDefault && !this.isLibrary ? this.default.price : this.currentPriceOriginal.price,
      this.currentPriceOriginal.isImageSizeDefault && !this.isLibrary ? this.default.width : this.currentPriceOriginal.width,
      this.currentPriceOriginal.isImageSizeDefault && !this.isLibrary ? this.default.height : this.currentPriceOriginal.height,
      this.currentPriceOriginal.isLengthDefault && !this.isLibrary ? this.default.length : this.currentPriceOriginal.length,
      this.currentPriceOriginal.isMatSizeDefault && !this.isLibrary ? this.default.matwidth : this.currentPriceOriginal.matWidth,
      this.currentPriceOriginal.isMatSizeDefault   && !this.isLibrary ? this.default.matheight : this.currentPriceOriginal.matHeight,
      this.currentPriceOriginal.isEditionedDefault && !this.isLibrary ? this.default.printnumber : this.currentPriceOriginal.editionNumber,
      this.currentPriceOriginal.isEditionedDefault && !this.isLibrary ? this.default.editionsize : this.currentPriceOriginal.editionSize,
      this.currentPriceOriginal.isEditionedDefault && !this.isLibrary ? this.default.editioned : this.currentPriceOriginal.isEditioned,
      this.currentPriceOriginal.isPriceDefault,
      this.currentPriceOriginal.isProcessDefault,
      this.currentPriceOriginal.isImageSizeDefault,
      this.currentPriceOriginal.isMatSizeDefault,
      this.currentPriceOriginal.isLengthDefault,
      this.currentPriceOriginal.isEditionedDefault,
      this.currentPriceOriginal.isAvailabilityDefault,
      ImageProcessModel.clone(this.currentPriceOriginal.process),
      ImageAvailabilityModel.clone(this.currentPriceOriginal.availability),
    );
  }

  private get priceDto(): ImagePriceDto {
    if (!this.currentPrice || !this.currentPriceOriginal) return null;

    const model = new ImagePriceModel(
      this.currentPrice.id,
      this.currentPrice.imageId,
      this.currentPrice.isProcessDefault && !this.isLibrary ? this.currentPriceOriginal.mediumId : this.currentPrice.mediumId,
      this.currentPrice.isProcessDefault && !this.isLibrary ? this.currentPriceOriginal.processId : this.currentPrice.processId,
      this.currentPrice.isAvailabilityDefault && !this.isLibrary ? this.currentPriceOriginal.availabilityId : this.currentPrice.availabilityId,
      this.currentPrice.isPriceDefault && !this.isLibrary ? this.currentPriceOriginal.price : this.currentPrice.price,
      this.currentPrice.isImageSizeDefault && !this.isLibrary ? this.currentPriceOriginal.width : this.currentPrice.width,
      this.currentPrice.isImageSizeDefault && !this.isLibrary ? this.currentPriceOriginal.height : this.currentPrice.height,
      this.currentPrice.isLengthDefault && !this.isLibrary ? this.currentPriceOriginal.length : this.currentPrice.length,
      this.currentPrice.isMatSizeDefault && !this.isLibrary ? this.currentPriceOriginal.matWidth : this.currentPrice.matWidth,
      this.currentPrice.isMatSizeDefault && !this.isLibrary ? this.currentPriceOriginal.matHeight : this.currentPrice.matHeight,
      this.currentPrice.isEditionedDefault && !this.isLibrary ? this.currentPriceOriginal.editionNumber : this.currentPrice.editionNumber,
      this.currentPrice.isEditionedDefault && !this.isLibrary ? this.currentPriceOriginal.editionSize : this.currentPrice.editionSize,
      this.currentPrice.isEditionedDefault && !this.isLibrary ? this.currentPriceOriginal.isEditioned : this.currentPrice.isEditioned,
      this.currentPrice.isPriceDefault,
      this.currentPrice.isProcessDefault,
      this.currentPrice.isImageSizeDefault,
      this.currentPrice.isMatSizeDefault,
      this.currentPrice.isLengthDefault,
      this.currentPrice.isEditionedDefault,
      this.currentPrice.isAvailabilityDefault,
      ImageProcessModel.clone(this.currentPrice.process),
      ImageAvailabilityModel.clone(this.currentPrice.availability),
    );

    return ImagePriceDto.toRequest(model);
  }

  public get imageThumbSrc(): string {
    return this.imageData && this.imageData.paths ? `https://${this.imageData.paths.mediaSmall}?key=${this.imageData.key}` : '';
  }

  constructor(
    private route: ActivatedRoute,
    private imageManagerService: ImageManagerService,
    private pagesService: PagesService,
    private measureUnitsService: MeasureUnitsService,
    private currenciesService: CurrenciesService,
    private imagesService: ImagesService,
    private imagesCounterService: ImagesCounterService,
    private authService: AuthService,
    private modalsService: ModalsService,
    private imageProcessesService: ImageProcessesService,
    private httpService: ImagesHttpService,
    private imageDetailsService: ImageDetailsService,
    private portfolioDefaultsService: PortfolioDefaultsService,
    private imagesBucketService: ImagesBucketService,
    private iFrameRoutingService: IFrameRoutingService,
    private imageManagerModalService: ImageManagerModalService,
    private textAlertModalService: TextAlertModalService,
    private websitesService: WebsitesService,
    private websitesHttpService: WebsitesHttpService,
    private websiteTourService: WebsiteTourService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private permissionsService: PermissionsService,
    private educationImageManagerService: EducationImageManagerService,
    private educationImagesService: EducationImagesService,
    private educationExhibitionsImagesCounterService: EducationExhibitionsImagesCounterService,
    private educationWebsitesService: EducationWebsitesService,
    private studentImageManagerService: StudentImageManagerService,
    private studentPortfoliosService: StudentPortfoliosService,
    private coreImageManagerService: CoreImageManagerService,
    private cdr: ChangeDetectorRef,
    public utilsService: UtilsService,
    public bag: BagService,
    public imagesAvailabilitiesService: ImagesAvailabilitiesService,
  ) {
    this.handlePriceAutoSave = this.utilsService.debounce(this.handlePriceAutoSave.bind(this), AUTOSAVE_DELAY);
  }

  public ngOnInit() {
    this.initPermissions();

    const advancedImageLabelPermisson: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.ADVANCED_IMAGE_LABEL,
    };

    this.initDefaultData();

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.isEducator = false;

      if (!subscription) {
        return;
      }

      this.isEducator = subscription.isEducator;
    });
    
    this.coreImageManagerService.userTypeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ImageManagerUserTypeModel) => {
      this.userData = data;

      this.isRegularUser = this.userData ? this.userData.type === 'user' : false;
      this.isEducatorImageManager = this.userData ? this.userData.type === 'educator' : false;
      this.isStudentImageManager = this.userData ? this.userData.type === 'student' : false;
    });

    this.studentImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: StudentImageManagerTabs) => {
      this.activeStudentImageManagerTab = key;
      
      this.initIsSimpleImageLabel();
    });

    this.educationImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: EducatorImageManagerTabs) => {
      this.activeImageManagerTab = key;

      this.isStudentsImageManagerTab = this.activeImageManagerTab === 'students';
      this.isExhibitionsImageManagerTab = this.activeImageManagerTab === 'exhibitions';
      this.isUserImageManagerTab = this.activeImageManagerTab === 'user';

      if (this.isStudentsImageManagerTab || this.isExhibitionsImageManagerTab) {
        this.isLibrary = false;
      }
      
      this.initCurrentPage();
      this.initIsSimpleImageLabel();
    });
    
    this.educationWebsitesService.websiteDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.exhibitionWebsite = website;

      this.initIsSimpleImageLabel();
    });

    const accountObservable: Observable<AccountModel> = this.authService.accountSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(account => !!account),
    );

    const currentWebsiteObservable: Observable<WebsiteModel> = this.websitesService.activeWebsiteSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(website => !!website)
    );

    combineLatest([accountObservable, currentWebsiteObservable]).subscribe(([account, website]) => {
      this.account = account;
      this.website = website;
      this.payPalEmail = website.payPalEmail;
      
      this.initIsSimpleImageLabel();
    });

    this.permissionsService.isUserHasPermissionsObservable([advancedImageLabelPermisson]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isSimpleImageLabelTogglerVisible = this.account && (this.account.isAdmin || this.account.isFullImageLabelAccessGranted) || isAllowed;
    });

    this.measureUnitsService.measureUnitsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((measureUnits: MeasureUnitModel[]) => {
      this.measureUnits = measureUnits;
    });

    this.measureUnitsService.websiteMeasureUnitSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((measureUnit: MeasureUnitModel) => {
      this.measureUnit = measureUnit;
      this.measureUnitSymbol = this.measureUnit ? this.measureUnit.symbol : '';
      this.measureUnitSymbolLong = this.measureUnit ? this.measureUnit.symbolLong : '';
    });

    this.currenciesService.websiteCurrencySubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((currency: CurrencyModel) => {
      this.currency = currency;
      this.currencySymbol = this.currency ? this.currency.symbol : '';
    });

    this.imageProcessesService.imageProcessesTypesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((mediums: ImageProcessTypeModel[]) => {
      this.initMediums(mediums);
    });

    this.imageProcessesService.imageProcessesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((processes: ImageProcessModel[]) => {
      this.allProcesses = processes;

      this.initCurrentProcesses();
    });

    this.imagesAvailabilitiesService.availabilitiesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((availabilities: ImageAvailabilityModel[]) => {
      this.initAvailabilities(availabilities);
    });

    this.imageDetailsService.imageDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((image: ImageModel) => {
      const isSameImage = this.imageData && image && this.imageData.key === image.key;

      this.imageData = image;

      if (isSameImage) {
        return;
      }
      
      this.imageSize = this.imageData ? `${this.imageData.widthPx}x${this.imageData.heightPx}px` : null;
      this.fileSize = this.imageData && this.imageData.fileSize ? `${this.imagesService.getFormattedFilesize(this.imageData.fileSize)}` : null;

      this.initPrices();
      this.initStudentImageLabel();
      this.initTours();

      if (this.pricesOptions.length) this.onPriceSelect(this.pricesOptions[0]);

      this.cdr.detectChanges();
    });

    this.educationImageManagerService.studentPortfolioDefaultsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (this.activeImageManagerTab !== 'students') return;
      
      this.onPortfolioDefaultsChange(portfolioDefaults);
    });

    this.educationImageManagerService.exhibitionPortfolioDefaultsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (this.activeImageManagerTab !== 'exhibitions') return;
      
      this.onPortfolioDefaultsChange(portfolioDefaults);
    });

    this.portfolioDefaultsService.portfolioDefaultsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (this.activeImageManagerTab !== 'user') return;

      this.onPortfolioDefaultsChange(portfolioDefaults);
    });

    this.educationImageManagerService.selectedStudentPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPortfolio: EducationStudentPortfolioModel) => {
      if (!selectedPortfolio || this.activeImageManagerTab !== 'students') return;

      this.openedTreeNode = selectedPortfolio.nodeId;
      this.portfolioId = selectedPortfolio.id;
    });

    this.educationImageManagerService.selectedExhibitionPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedExhibition: EducationExhibitionPortfolioModel) => {
      this.selectedExhibition = selectedExhibition;
    });

    this.imagesBucketService.imagesBucketSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((bucket: string) => {
      this.bucket = bucket;
    });

    if (this.isInModal) {
      this.imageManagerModalService.onPortfolioSelect.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ id }) => {
        this.portfolioId = id;

        this.pageId = id || null;
        this.isLibrary = this.isUserImageManagerTab && id === 0;
      });
    }

    if (!this.isInModal) {
      this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
        this.selectedPage = selectedPage;

        this.initCurrentPage();
      });
    }

    this.imageManagerService.isPageBlockedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPageBlocked: boolean) => {
      this.isPageBlocked = isPageBlocked;
    });

    this.addVisibleLocation(TOUR_KEY);

    this.initDefaultData();

    this.initSubjectsListeners();
  }

  private initPermissions(): void {
    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isStudent: boolean) => {
      this.isStudent = isStudent;
    });
  }

  private initStudentImageLabel(): void {
    if (!this.isStudentsImageManagerTab) {
      this.studentImageLabel = null;
      
      return;
    }

    this.studentImageLabel = new StudentImageLabelModel(this.imageData, this.default);
  }

  private initIsSimpleImageLabel(): void {
    if (this.isEducator) {
      if (this.isStudentsImageManagerTab) {
        this.isSimpleImageLabel = true;
  
        return;
      }
  
      if (this.isExhibitionsImageManagerTab) {
        this.isSimpleImageLabel = this.exhibitionWebsite ? this.exhibitionWebsite.isSimpleImageLabel : false;
  
        return;
      }
    }

    if (this.isStudent) {
      if (this.isStudentTab) {
        this.isSimpleImageLabel = true;
  
        return;
      }
    }
      
    this.isSimpleImageLabel = this.website ? this.website.isSimpleImageLabel : false;
  }

  private initCurrentPage(): void {
    this.pageId = this.selectedPage ? this.selectedPage.id : null;
    this.isLibrary = this.isUserImageManagerTab && this.pageId === -1;

    if (this.isUserImageManagerTab) {
      this.websitesService.fetchWebsite();
    }

    this.initImageData();
    this.initTours();
    this.fetchIsInfoLinkEnabledForAllImages();
  }

  private onPortfolioDefaultsChange(portfolioDefaults: PortfolioDefaultsModel): void {
    this.initPortfolioDefaults(portfolioDefaults);
    this.initPrices();

    if (this.pricesOptions.length) this.onPriceSelect(this.pricesOptions[0]);
  }

  private initImageData(): void {
    if (!this.imageData) {
      return;
    }

    if ((this.isLibrary && this.imageData.portfolioId === 0) || (this.imageData.portfolioId === this.pageId)) {
      return;
    }

    this.imageData = null;
    this.imageSize = null;
    this.fileSize = null;

    this.cdr.detectChanges();
  }

  private initTours(): void {
    if (this.tab !== this.TABS.IMAGE_LABEL) {
      this.removeVisibleItem(KEYS.IMAGE_MANAGER_IMAGE_LABEL);
    }

    if (this.isLibrary) {
      this.removeVisibleItem(KEYS.PORTFOLIO_DEFAULTS_BUTTON);
    }

    if (!!this.imageData) {
      if (!this.isLibrary) {
        this.addVisibleItem(KEYS.PORTFOLIO_DEFAULTS_BUTTON);
      }

      if (this.tab === this.TABS.IMAGE_LABEL) {
        this.addVisibleItem(KEYS.IMAGE_MANAGER_IMAGE_LABEL);
      }

      return;
    }
  }

  private initMediums(mediums: ImageProcessTypeModel[]): void {
    this.mediums = mediums;
    this.mediumsOptions = mediums ? mediums.map((medium: ImageProcessTypeModel) => new SelectOption(medium.type, `${medium.id}`)) : [];

    this.initCurrentMedium();
  }

  private initAvailabilities(availabilities: ImageAvailabilityModel[]): void {
    this.availabilities = availabilities;
    this.availabilitiesOptions = availabilities ? availabilities.map((availability: ImageAvailabilityModel) => new SelectOption(availability.text, `${availability.id}`)) : [];

    this.initAvailability();
  }

  handleLibrarySelect() {
    this.tab = this.TABS.IMAGE_LABEL;
    this.portfolioId = LIBRARY_ID;
  }

  onNodeSelected(node): void {
    if (!node) {
      return this.handleLibrarySelect();
    }

    this.fetchIsInfoLinkEnabledForAllImages();

    this.openedTreeNode = node.nodeId;
    this.portfolioId = node.id;

    if (this.portfolioId === LIBRARY_ID && this.tab !== this.TABS.IMAGE_LABEL) {
      this.tab = this.TABS.IMAGE_LABEL;
    }

    this.initDefaultData();
  }

  fetchIsInfoLinkEnabledForAllImages() {
    if (!this.pageId) return;

    this.imageManagerService.getInfoLinkVisibility(this.pageId).subscribe(({ isInfoLinkEnabledForAllImages }) => {
      this.isDefaultImageLabelEnabled = isInfoLinkEnabledForAllImages;
    });
  }

  initPortfolioDefaults(data: PortfolioDefaultsModel) {
    if (!data) return;

    this.default.loaded        = true;
    this.default.title         = data.title || '';
    this.default.caption       = data.caption || '';
    this.default.process       = data.printProcess || '';
    this.default.processother  = data.otherPrintProcess || '';
    this.default.width         = data.width;
    this.default.height        = data.height;
    this.default.matwidth      = data.matWidth;
    this.default.matheight     = data.matHeight;
    this.default.length        = data.length;
    this.default.editioned     = data.isEditioned || false;
    this.default.printnumber   = data.printNumber || '';
    this.default.editionsize   = data.editionSize || '';
    this.default.price         = data.price ? data.price / 100 : '';
    this.default.pricing       = data.pricingStructure || '';
    this.default.availability  = data.availability || 0;
    this.default.yearproduced  = data.year || '';
    this.default.copyright     = data.copyright || '';
    this.default.inquiryLinkText = data.inquiryLinkText || '';
    this.default.purchaseText  = data.purchaseText || '';
    this.default.purchaseInfo  = data.purchaseInfo || '';
    this.default.publish       = null;
    this.default.processTypeId = data.processTypeId || this.authService.user.PrimaryMediumId;
    this.default.isInquireLinkVisible = data.isInquireLinkVisible || false;
    this.default.isPurchaseButtonVisible = data.isPurchaseButtonVisible || false;
  }

  selectTab(tab: string) {
    this.tab = tab;
    this.newProcessName = '';

    this.initCurrentMedium();
    this.initCurrentProcesses();
    this.initAvailability();
    this.initCurrentProcess();
  }

  private initAvailability(): void {
    return this.isDetails ? this.initCurrentAvailability() : this.initDefaultCurrentAvailability();
  }

  private initDefaultData() {
    this.default = {
      loaded: false,
      title: null,
      process: null,
      processother: null,
      caption: null,
      purchaseInfo: null,
      width: null,
      height: null,
      matwidth: null,
      matheight: null,
      length: null,
      editioned: false,
      printnumber: null,
      editionsize: null,
      yearproduced: null,
      price: null,
      pricing: null,
      availability: null,
      copyright: null,
      publish: false,
      pricingStructure: '',
      processTypeId: null,
    };

    this.initStudentImageLabel();
  }

  private initSubjectsListeners() {
    this.pagesService.selectedNodeSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onNodeSelected.bind(this));

    this.saveDefaultsSubject.pipe(
      debounceTime(AUTOSAVE_DELAY),      // wait 500ms after each keystroke before considering the term
      distinctUntilChanged(),)         // ignore if next search term is same as previous
      .subscribe((value: PortfolioDefaultsRequest) => {
        if (!this.portfolioDefaultsSaveHandlers[this.activeImageManagerTab]) {
          return;
        }

        this.portfolioDefaultsSaveHandlers[this.activeImageManagerTab](value).subscribe(() => {});
      });

    this.saveDetailsSubject.pipe(
      debounceTime(AUTOSAVE_DELAY),      // wait 500ms after each keystroke before considering the term
      distinctUntilChanged(),)         // ignore if next search term is same as previous
      .subscribe((value: ImageLabelDto) => {
        if (this.isStudent) {
          if (this.studentImageLabelSaveHandlers[this.activeStudentImageManagerTab]) {
            this.studentImageLabelSaveHandlers[this.activeStudentImageManagerTab](value);
          }

          return;
        }
        
        if (!this.educatorImageLabelSaveHandlers[this.activeImageManagerTab]) {
          return;
        }

        this.educatorImageLabelSaveHandlers[this.activeImageManagerTab](value).subscribe(() => {
          this.fetchIsInfoLinkEnabledForAllImages();
      
          this.imageDetailsService.imageDetailsSubject.next(this.imageData);
        });
      });
  }

  private savePortfolioDefaultsForExhibition(portfolioDefaults: PortfolioDefaultsRequest): Observable<any> {
    return this.educationImageManagerService.updateExhibitionPortfolioDefaults(this.selectedExhibition, portfolioDefaults);
  }

  private savePortfolioDefaultsForUser(data): Observable<any> {
    return this.imageManagerService.savePortfolioDefaults(data);
  }

  private saveImageLabelForExhibition(data: ImageLabelDto): Observable<any> {
    return this.educationImagesService.updateImageLabel(
      this.selectedExhibition.institutionId,
      this.selectedExhibition.classId,
      this.selectedExhibition.websiteId,
      this.selectedExhibition.id,
      data.imageId,
      data,
    );
  }

  private saveImageLabelForStudent(details: ImageLabelDto): Subscription {
    return this.studentPortfoliosService.saveImageDetails({
      details,
    }).subscribe(() => {
      this.imageDetailsService.imageDetailsSubject.next(this.imageData);
    });
  }

  private saveImageLabelForUser(data: ImageLabelDto): Observable<any> {
    return this.imageManagerService.saveImageDetails(data);
  }

  public publishImage() {
    if (!this.publishImageHandlers[this.activeImageManagerTab]) return;

    this.publishImageHandlers[this.activeImageManagerTab]().subscribe(() => {
      this.imageDetailsService.setIsPublishedState(this.imageData.isPublished);
    });
  }

  private publishExhibitionImage(): Observable<any> {
    this.educationExhibitionsImagesCounterService.handlePublish(this.portfolioId, this.imageData.isPublished);

    return this.educationImageManagerService.updateExhibitionImagePublishState(this.selectedExhibition, this.imageData.id, this.imageData.isPublished);
  }

  private publishUserImage(): Observable<any> {
    this.imagesCounterService.handlePublish(this.portfolioId, this.imageData.isPublished);

    return this.imageManagerService.publishImage({
      imageId: this.imageData.id,
      isPublish: this.imageData.isPublished,
    });
  }

  toggleDetails() {
    this.collapsed = !this.collapsed;
  }

  togglePanel(name) {
    let state = this.panel[name];

    this.panel = {
      properties: false,
      ownership: false,
      pricing: false,
    };

    this.panel[name] = !state;
  }

  public saveDetails(): void {
    this.showLabelAutosave();

    this.saveDetailsSubject.next(new ImageLabelDto(
      this.imageData.id,
      this.imageData.websiteId,
      this.imageData.portfolioId,
      this.imageData.title,
      this.imageData.isTitleDefault,
      this.imageData.caption,
      this.imageData.isCaptionDefault,
      this.imageData.purchaseInfo,
      this.imageData.isPurchaseInfoDefault,
      this.imageData.year,
      this.imageData.isYearDefault,
      this.imageData.copyright,
      this.imageData.isCopyrightDefault,
      this.imageData.pricingStructure,
      this.imageData.isPricingStructureDefault,
      this.imageData.isPurchaseLinkVisibilityDefault,
      this.imageData.isInquireLinkVisible,
      this.imageData.isPurchaseButtonVisible,
      this.imageData.isPublished,
      this.imageData.isInfoVisible,
    ));

    this.imageDetailsService.imageDetailsSubject.next(this.imageData);
  }

  convertDimensionsPropsToMain(container) {
    this.dimensionsProps.forEach(key => {
      if (container[key] === '') container[key] = null;

      container[key] = this.measureUnitsService.convertToMain(container[key]);
    });
    
    return container;
  }

  public saveDefaults(): void {
    const price: number = Math.round(this.toFixed2(this.default.price) * 100);

    const data = new PortfolioDefaultsRequest(
      this.selectedPage.id,
      this.default.title,
      this.default.caption,
      this.default.process,
      this.default.processother,
      this.default.copyright,
      this.default.purchaseInfo,
      null,
      this.default.editioned,
      this.default.editionsize,
      this.default.printnumber,
      this.default.width,
      this.default.height,
      this.default.length,
      this.default.matwidth,
      this.default.matheight,
      Number.isNaN(price) ? 0 : price,
      this.default.pricing,
      this.default.inquiryLinkText,
      this.default.purchaseText,
      null,
      this.default.availability,
      this.default.isInquireLinkVisible,
      this.default.isPurchaseButtonVisible,
      this.default.yearproduced,
      this.default.processTypeId,
    );

    this.showDefaultsAutosave();

    this.saveDefaultsSubject.next(data);

    this.bag.change.next({ defaultsOnSave: data });
  }

  public createNewDefaultProcess(form: NgForm) {
    this.isInProgress = true;

    this.processAddError = null;

    const existingProcess: ImageProcessModel = this.processes.find(process => process.processName === this.newProcessName);

    if (existingProcess) {
      this.default.process = existingProcess.processName;

      this.initCurrentProcessProps(existingProcess);

      return this.afterProcessCreating();
    }

    const newProcess = {
      processName: this.newProcessName,
      type: this.default.processTypeId,
    };

    this.imageProcessesService.addNewProcess(newProcess).pipe(
      catchError(e => {
        try {
          this.processAddError = e.error.message;
        } catch {}

        return throwError(() => e);
      }),
      finalize(() => {
        this.afterProcessCreating();
      }),
    ).subscribe((process: ImageProcessModel) => {
      this.default.process = process.processName;

      this.saveDefaults();

      this.newProcessName = '';

      this.initCurrentProcessProps(process);

      return this.imageProcessesService.updateImageProcesses();
    });
  }

  public createNewProcess(): void {
    this.isInProgress = true;

    this.processAddError = null;

    const existingProcess = this.processes.find(process => process.processName === this.newProcessName);

    if (existingProcess) {
      this.currentPrice.processId = existingProcess.processId;
      
      this.initCurrentProcess();

      return this.afterProcessCreating();
    }

    const newProcess = {
      processName: this.newProcessName,
      type: this.currentMedium.id,
    };

    this.imageProcessesService.addNewProcess(newProcess).pipe(
      catchError(e => {
        try {
          this.processAddError = e.error.message;
        } catch {}

        return throwError(() => e);
      }),
      finalize(() => {
        this.afterProcessCreating();
      }),
    ).subscribe((process: ImageProcessModel) => {
      this.currentPrice.processId = process.processId;

      this.preparePriceAutoSave();

      this.initCurrentProcessProps(process);

      this.newProcessName = '';

      return this.imageProcessesService.updateImageProcesses();
    });
  }

  private afterProcessCreating(): void {
    this.isInProgress = false;
  }

  onVisibilityChange(form: NgForm) {
    this.imageData.isInfoVisible = !this.imageData.isInfoVisible;

    if (this.imageData.isInfoVisible) this.isDefaultImageLabelEnabled = false;

    this.saveDetails();
  }

  onInfoDefaultClick(isChecked) {
    const initialInfoVisibilityValue = this.imageData.isInfoVisible;

    this.imageData.isInfoVisible = isChecked;

    this.showDefaultsAutosave();

    return this.imageManagerService.setInfoLinkVisibility(this.portfolioId, { InfoVisibility: isChecked }).pipe(
      catchError(e => {
        this.imageData.isInfoVisible = initialInfoVisibilityValue;

        return throwError(() => e);
      })
    ).subscribe(() => { this.isDefaultImageLabelEnabled = isChecked; });
  }

  applyInquiryLinkTextToAllPortfolios(event) {
    event.preventDefault();

    this.textAlertModalService.show({
      message: 'Are you sure that you want to apply this inquiry link text across all portfolios?',
      submitHandler: () => {
        return this.imageManagerService.applyInquiryLinkTextToAllPortfolio({ inquiryLinkText: this.default.inquiryLinkText });
      },
      showCancelButton: true,
      submitButtonText: 'Yes',
      cancelButtonText: 'No',
    });
  }

  applyPurchaseTextToAllPortfolios(event) {
    event.preventDefault();

    this.textAlertModalService.show({
      message: 'Are you sure that you want to apply this purchase text across all portfolios?',
      submitHandler: () => {
        return this.imageManagerService.applyPurchaseTextToAllPortfolios({ purchaseText: this.default.purchaseText });
      },
      showCancelButton: true,
      submitButtonText: 'Yes',
      cancelButtonText: 'No',
    });
  }

  public onDefaultMediumSelect(option: SelectOption, form: NgForm): void {
    this.default.processTypeId = Number.parseInt(option.value);

    this.initCurrentMediumForDefaults();

    this.save();
  }

  public onDefaultProcessSelect(option: SelectOption, form: NgForm): void {
    const processId = Number.parseInt(option.value);

    const process = this.processes.find((process: ImageProcessModel) => process.processId === processId);

    this.initCurrentProcessProps(process);

    if (option.label === this.imageProcessesService.newProcessOptionName) return;

    this.default.process = option.label;

    this.save();
  }

  public onDefaultAvailabilitySelect(option: SelectOption, form: NgForm): void {
    const availability = option ? this.availabilities.find(availability => `${availability.id}` === option.value) : null;

    this.initCurrentAvailabilityProps(availability);

    this.default.availability = availability ? availability.id : null;

    this.save();
  }

  private save() {
    return this.isDetails ? this.saveDetails() : this.saveDefaults();
  }

  public openProcessesModal() {
    this.modalsService.open(this.imageProcessesService.modalId);
  }

  private showLabelAutosave() {
    clearTimeout(this.labelAutosaveTimeout);

    this.isLabelAutosaveShown = true;

    this.labelAutosaveTimeout = setTimeout(() => {
      this.isLabelAutosaveShown = false;
    }, 2500);
  }

  private showDefaultsAutosave() {
    clearTimeout(this.defaultsAutosaveTimeout);

    this.isDefaultsAutosaveShown = true;

    this.defaultsAutosaveTimeout = setTimeout(() => {
      this.isDefaultsAutosaveShown = false;
    }, 2500);
  }

  public onPriceSelect(option: SelectOption): void {
    if (!option || !this.imageData) return;

    this.isNewPrice = option.value === OPTIONS_TEXTS.ADD_PRICE;
    this.currentPriceOption = option;

    this.currentPriceOriginal = this.isNewPrice ? this.newPrice : this.imageData.prices.find(price => price.id === parseInt(option.value, 10));
    this.currentPrice = this.priceModel;

    this.initCurrentPriceDropdownText();
    this.initCurrentMedium();
    this.initCurrentProcess();
    this.initCurrentAvailability();
  }

  public onIsPriceDefaultClick(isPriceDefault: boolean): void {
    this.currentPrice.isPriceDefault = isPriceDefault;
    this.currentPrice.price = isPriceDefault ? this.default.price : this.currentPriceOriginal.price;

    this.preparePriceAutoSave();
  }

  public onIsImageSizeDefaultClick(isSizeDefault: boolean): void {
    this.currentPrice.isImageSizeDefault = isSizeDefault;
    this.currentPrice.width = isSizeDefault ? this.default.width : this.currentPriceOriginal.width;
    this.currentPrice.height = isSizeDefault ? this.default.height : this.currentPriceOriginal.height;

    this.preparePriceAutoSave();
  }

  public onWidthChange(e: KeyboardEvent): void {
    const target = <HTMLInputElement>e.target;

    this.currentPrice.width = target.value ? Number.parseFloat(target.value) : null;

    this.preparePriceAutoSave();
  }

  public onHeightChange(e: KeyboardEvent): void {
    const target = <HTMLInputElement>e.target;

    this.currentPrice.height = target.value ? Number.parseFloat(target.value) : null;

    this.preparePriceAutoSave();
  }

  public onLengthChange(e: KeyboardEvent): void {
    const target = <HTMLInputElement>e.target;

    this.currentPrice.length = target.value ? Number.parseFloat(target.value) : null;

    this.preparePriceAutoSave();
  }

  public onMatWidthChange(e: KeyboardEvent): void {
    const target = <HTMLInputElement>e.target;

    this.currentPrice.matWidth = target.value ? Number.parseFloat(target.value) : null;

    this.preparePriceAutoSave();
  }

  public onMatHeightChange(e: KeyboardEvent): void {
    const target: HTMLInputElement = <HTMLInputElement>e.target;

    this.currentPrice.matHeight = target.value ? Number.parseFloat(target.value) : null;

    this.preparePriceAutoSave();
  }

  public onIsMatSizeDefaultClick(isMatSizeDefault: boolean): void {
    this.currentPrice.isMatSizeDefault = isMatSizeDefault;
    this.currentPrice.matWidth = isMatSizeDefault ? this.default.matwidth : this.currentPriceOriginal.matWidth;
    this.currentPrice.matHeight = isMatSizeDefault ? this.default.matheight : this.currentPriceOriginal.matHeight;

    this.preparePriceAutoSave();
  }

  public onMediumSelect(option: SelectOption): void {
    const medium = this.mediums.find((medium: ImageProcessTypeModel) => `${medium.id}` === option.value);

    this.initCurrentMediumProps(medium);

    this.currentPrice.mediumId = medium ? medium.id : null;
    this.currentPrice.processId = null;

    this.initCurrentProcesses();
    this.initCurrentProcess();

    this.preparePriceAutoSave();
  }

  public onProcessSelect(option: SelectOption): void {
    const process = this.processes.find((process: ImageProcessModel) => `${process.processId}` === option.value);

    this.initCurrentProcessProps(process);

    this.currentPrice.processId = process ? process.processId : null;

    this.preparePriceAutoSave();
  }

  public onIsProcessDefaultClick(isProcessDefault: boolean): void {
    this.currentPrice.isProcessDefault = isProcessDefault;
    this.currentPrice.mediumId = isProcessDefault ? this.default.processTypeId : this.currentPriceOriginal.mediumId;

    this.initCurrentMedium();

    this.currentPrice.processId = isProcessDefault ? this.getDefaultProcessId() : this.currentPriceOriginal.processId;

    this.initCurrentProcess();

    this.preparePriceAutoSave();
  }

  private getDefaultProcessId(): number {
    if (!this.default) return null;

    const process: ImageProcessModel = this.processes.find(p => p.processName === this.default.process);

    return process ? process.processId : null;
  }

  private initCurrentMedium(): void {
    if (!this.currentPrice || !this.mediums) return;

    if (this.tab === this.TABS.PORTFOLIO_DEFAULTS) return this.initCurrentMediumForDefaults();

    const currentMedium = this.mediums.find((medium: ImageProcessTypeModel) => medium.id === this.currentPrice.mediumId);

    if (currentMedium) {
      this.initCurrentMediumProps(currentMedium);
      
      return this.initCurrentProcesses();
    }

    this.initCurrentMediumProps(this.getUserMedium());

    this.currentPrice.mediumId = this.currentMedium ? this.currentMedium.id : null;

    this.initCurrentProcesses();
  }

  private initCurrentMediumForDefaults(): void {
    const currentMedium = this.mediums.find((medium: ImageProcessTypeModel) => medium.id === this.default.processTypeId);

    if (currentMedium) {
      this.initCurrentMediumProps(currentMedium);
      return this.initCurrentProcesses();
    }

    this.initCurrentMediumProps(this.getUserMedium());

    this.default.processTypeId = this.currentMedium ? this.currentMedium.id : null;

    this.initCurrentProcesses();
  }

  private initCurrentMediumProps(medium: ImageProcessTypeModel): void {
    this.currentMedium = medium;

    this.currentMediumOption = medium ? new SelectOption(medium.type, `${medium.id}`) : null;
  }

  private initCurrentProcesses(): void {
    return this.tab === this.TABS.IMAGE_LABEL ? this.initCurrentProcessesForImage() : this.initCurrentProcessesForDefaults();
  }

  private initCurrentProcessesForImage(): void {
    if (!this.allProcesses || !this.currentMedium || !this.currentPrice) {
      return;
    }

    this.processes = this.allProcesses.filter((process: ImageProcessModel) => process.type === this.currentMedium.id || !process.type);

    this.processesOptions = this.processes.map((process: ImageProcessModel) => new SelectOption(process.processName, `${process.processId}`));
  }

  private initCurrentProcessesForDefaults(): void {
    if (!this.allProcesses || !this.default) return;

    this.processes = this.allProcesses.filter((process: ImageProcessModel) => process.type === this.default.processTypeId || !process.type);

    this.processesOptions = this.processes.map((process: ImageProcessModel) => new SelectOption(process.processName, `${process.processId}`));
  }

  private getUserMedium(): ImageProcessTypeModel {
    if (!this.mediums || !this.account) return null;

    return this.mediums.find((medium: ImageProcessTypeModel) => medium.id === this.account.primaryMediumId);
  }

  private initCurrentProcess(): void {
    if (!this.currentPrice || !this.processes) return;

    if (this.tab === this.TABS.PORTFOLIO_DEFAULTS) return this.initDefaultCurrentProcess();

    const isProcessDefault = this.currentPrice.isProcessDefault;

    const currentProcess = this.processes.find((process: ImageProcessModel) => {
      return isProcessDefault ? process.processName === this.default.process : process.processId === this.currentPrice.processId;
    });

    this.initCurrentProcessProps(currentProcess);
  }

  private initDefaultCurrentProcess(): void {
    if (!this.default || !this.processes) {
      return;
    }

    const currentProcess = this.processes.find((process: ImageProcessModel) => process.processName === this.default.process);

    this.initCurrentProcessProps(currentProcess);

    if (this.currentProcess) {
      return;
    } 

    this.initCurrentProcessProps(this.processes[0]);

    this.default.process = this.currentProcess.processName;
  }

  private initCurrentProcessProps(process: ImageProcessModel): void {
    this.currentProcess = process;

    this.currentProcessOption = process ? new SelectOption(process.processName, `${process.processId}`) : null;
  }

  public onIsLengthDefaultClick(isLengthDefault: boolean): void {
    this.currentPrice.isLengthDefault = isLengthDefault;
    this.currentPrice.length = isLengthDefault ? this.default.length : this.currentPriceOriginal.length;

    this.preparePriceAutoSave();
  }

  public onIsAvailabilityDefaultClick(isAvailabilityDefault: boolean): void {
    this.currentPrice.isAvailabilityDefault = isAvailabilityDefault;
    this.currentPrice.availabilityId = isAvailabilityDefault ? this.default.availability : this.currentPriceOriginal.availabilityId;

    this.initCurrentAvailability();

    this.preparePriceAutoSave();
  }

  public onAvailabilitySelect(option: SelectOption): void {
    const availability = option ? this.availabilities.find(availability => `${availability.id}` === option.value) : null;

    this.initCurrentAvailabilityProps(availability);

    this.currentPrice.availabilityId = availability ? availability.id : null;

    this.preparePriceAutoSave();
  }

  private initCurrentAvailability(): void {
    if (!this.currentPrice || !this.availabilities) return;

    if (this.tab === this.TABS.PORTFOLIO_DEFAULTS) return this.initDefaultCurrentAvailability();

    const currentAvailability = this.availabilities.find((availability: ImageAvailabilityModel) => availability.id === this.currentPrice.availabilityId);

    this.initCurrentAvailabilityProps(currentAvailability);

    if (this.currentAvailability) return;

    this.initCurrentAvailabilityProps(this.availabilities[0]);

    this.currentPrice.availabilityId = this.currentAvailability ? this.currentAvailability.id : null;
  }

  private initDefaultCurrentAvailability(): void {
    if (!this.default || !this.availabilities) return;

    const currentAvailability = this.availabilities.find((availability: ImageAvailabilityModel) => availability.id === this.default.availability);

    this.initCurrentAvailabilityProps(currentAvailability);

    if (this.currentAvailability) return;

    this.initCurrentAvailabilityProps(this.availabilities[0]);

    this.default.availability = this.currentAvailability.id;
  }

  private initCurrentAvailabilityProps(availability: ImageAvailabilityModel): void {
    this.currentAvailability = availability;

    this.currentAvailabilityOption = availability ? new SelectOption(availability.text, `${availability.id}`) : null;
  }

  public onIsEditionedDefaultClick(isEditionedDefault: boolean): void {
    this.currentPrice.isEditionedDefault = isEditionedDefault;

    this.currentPrice.isEditioned = isEditionedDefault ? this.default.editioned : this.currentPriceOriginal.isEditioned;
    this.currentPrice.editionNumber = isEditionedDefault ? this.default.printnumber : this.currentPriceOriginal.editionNumber;
    this.currentPrice.editionSize = isEditionedDefault ? this.default.editionsize : this.currentPriceOriginal.editionSize;

    this.preparePriceAutoSave();
  }

  public onEditionedChange(): void {
    this.preparePriceAutoSave();
  }

  public onAddButtonKeyDown(e: KeyboardEvent): void {
    if (e.keyCode !== KEY_CODES.ENTER) return;

    e.target.dispatchEvent(new Event('click'));
  }

  public onDeleteButtonKeyDown(e: KeyboardEvent): void {
    if (e.keyCode !== KEY_CODES.ENTER) return;

    e.target.dispatchEvent(new Event('click'));
  }

  public preparePriceAutoSave(): void {
    this.lastChangeTimeStamp = new Date().getTime();
    
    this.handlePriceAutoSave();
  }

  public handlePriceAutoSave(): void {
    this.addPrice();
  }

  public addPrice(): void {
    if (!this.currentPrice) return;

    return this.isNewPrice ? this.addNewPrice() : this.updatePrice();
  }

  public updatePrice(): void {
    if (this.isInProgress || !this.currentPrice) {
      return;
    }

    const changedAt = this.lastChangeTimeStamp;

    this.updatePriceHandlers[this.activeImageManagerTab]().subscribe((price: ImagePriceModel) => {
      if (!price || !this.imageData || !this.imageData.prices) {
        return;
      }

      const idx = this.imageData.prices.findIndex((ip: ImagePriceModel) => ip.id === price.id);

      this.imageData.prices[idx] = price;

      this.initPrices();

      if (changedAt !== this.lastChangeTimeStamp) {
        return;
      }

      this.showLabelAutosave();

      const updatedPriceOption = this.pricesOptions.find(option => Number.parseInt(option.value, 10) === price.id);

      this.onPriceSelect(updatedPriceOption);

      this.imageDetailsService.imageDetailsSubject.next(this.imageData);
    });
  }

  private updateExhibitionImagePrice(): Observable<ImagePriceModel> {
    return this.educationImagesService.updatePrice(
      this.selectedExhibition.institutionId,
      this.selectedExhibition.classId,
      this.selectedExhibition.websiteId,
      this.selectedExhibition.id,
      this.currentPrice.imageId,
      this.priceDto
    );
  }

  private updateUserImagePrice(): Observable<ImagePriceModel> {
    return this.httpService.updatePrice(this.currentPrice.imageId, this.priceDto);
  }

  public addNewPrice(): void {
    if (this.isInProgress || !this.currentPrice) return;

    this.isInProgress = true;

    this.addPriceHandlers[this.activeImageManagerTab]().subscribe((price: ImagePriceModel) => {
      this.showLabelAutosave();
      this.onNewPriceAdded(price);

      this.imageDetailsService.imageDetailsSubject.next(this.imageData);
    }, err => this.onNewPriceAddingFailed(err));
  }

  private onNewPriceAdded(price: ImagePriceModel): void {
    this.isInProgress = false;
    this.isNewPrice = false;

    if (!price || !this.imageData || !this.imageData.prices) return;

    this.imageData.prices.push(price);

    this.initPrices();

    const newPriceOption = this.pricesOptions.find(option => parseInt(option.value, 10) === price.id);

    this.onPriceSelect(newPriceOption);
  }

  private onNewPriceAddingFailed(err: HttpErrorResponse): void {
    this.isInProgress = false;

    this.error = err.error.message;
  }

  private addExhibitionImagePrice(): Observable<ImagePriceModel> {
    return this.educationImagesService.createPrice(
      this.selectedExhibition.institutionId,
      this.selectedExhibition.classId,
      this.selectedExhibition.websiteId,
      this.selectedExhibition.id,
      this.currentPrice.imageId,
      this.priceDto
    );
  }

  private addUserImagePrice(): Observable<ImagePriceModel> {
    return this.httpService.addNewPrice(this.currentPrice.imageId, this.priceDto);
  }

  public onPriceDelete() {
    return this.isNewPrice ? this.clearPrice() : this.deletePrice();
  }

  public deletePrice() {
    if (this.isInProgress) return;

    this.isInProgress = true;

    return this.deletePriceHandlers[this.activeImageManagerTab]().subscribe((price: ImagePriceModel) => {
      this.isInProgress = false;

      if (!price || !this.imageData || !this.imageData.prices) return;

      this.imageData.prices = this.imageData.prices.filter((ip: ImagePriceModel) => ip.id !== price.id);

      this.initPrices();
      this.onPriceSelect(this.pricesOptions[0]);

      this.imageDetailsService.imageDetailsSubject.next(this.imageData);
    });
  }

  public clearPrice(): void {
    this.error = '';

    this.currentPrice = this.newPrice;

    this.initCurrentPriceDropdownText();
  }

  private deleteExhibitionImagePrice(): Observable<ImagePriceModel> {
    return this.educationImagesService.deletePrice(
      this.selectedExhibition.institutionId,
      this.selectedExhibition.classId,
      this.selectedExhibition.websiteId,
      this.selectedExhibition.id,
      this.currentPrice.imageId,
      this.currentPrice.id
    );
  }

  private deleteUserImagePrice(): Observable<ImagePriceModel> {
    return this.httpService.deletePrice(this.currentPrice.imageId, this.currentPrice.id);
  }

  public onYearProducedChange(e: KeyboardEvent): void {
    if (!this.isLibrary && this.imageData.isYearDefault) return;

    const target: HTMLInputElement = <HTMLInputElement>e.target;

    const value: number = this.parseIntValue(target.value);

    this.imageData.year = value ? `${value}` : '';

    target.value = this.imageData.year;

    this.saveDetails();
  }

  public onEditionNumberChange(e: KeyboardEvent): void {
    if (!this.isLibrary && this.currentPrice.isEditionedDefault) return;

    const target: HTMLInputElement = <HTMLInputElement>e.target;

    const value: number = this.parseIntValue(target.value);

    this.currentPrice.editionNumber = value ? `${value}` : '';

    target.value = this.currentPrice.editionNumber;

    this.preparePriceAutoSave();
  }

  public onEditionSizeChange(e: KeyboardEvent): void {
    if (!this.isLibrary && this.currentPrice.isEditionedDefault) return;

    const target: HTMLInputElement = <HTMLInputElement>e.target;

    const value: number = this.parseIntValue(target.value);

    this.currentPrice.editionSize = value ? `${value}` : '';

    target.value = this.currentPrice.editionSize;

    this.preparePriceAutoSave();
  }

  public onYearProducedDefaultsChange(e: KeyboardEvent): void {
    const target: HTMLInputElement = <HTMLInputElement>e.target;

    const value: number = this.parseIntValue(target.value);

    this.default.yearproduced = value ? `${value}` : '';

    target.value = this.default.yearproduced;

    this.saveDefaults();
  }

  public onEditionNumberDefaultsChange(e: KeyboardEvent): void {
    const target: HTMLInputElement = <HTMLInputElement>e.target;

    const value: number = this.parseIntValue(target.value);

    this.default.printnumber = value ? `${value}` : '';

    target.value = this.default.printnumber;

    this.saveDefaults();
  }

  public onEditionSizeDefaultsChange(e: KeyboardEvent): void {
    const target: HTMLInputElement = <HTMLInputElement>e.target;

    const value: number = this.parseIntValue(target.value);

    this.default.editionsize = value ? `${value}` : '';

    target.value = this.default.editionsize;

    this.saveDefaults();
  }

  private parseIntValue(value: string, defaultValue: any = null): number {
    const numberValue: number = Number.parseInt(value);

    return Number.isNaN(numberValue) ? defaultValue : numberValue;
  }

  public onPriceValueChange(e: KeyboardEvent): void {
    const target: HTMLInputElement = <HTMLInputElement>e.target;

    this.currentPrice.price = Math.round(this.toFixed2(Number.parseFloat(target.value)) * 100) / 100;

    if (Number.isNaN(this.currentPrice.price)) this.currentPrice.price = 0;

    this.initPrices();
    this.initCurrentPriceDropdownText();

    target.value = `${this.currentPrice.price}`;

    this.preparePriceAutoSave();
  }

  public onDefaultPriceChange(e: KeyboardEvent): void {
    const target: HTMLInputElement = <HTMLInputElement>e.target;

    this.default.price = Math.round(this.toFixed2(parseFloat(target.value)) * 100) / 100;

    if (Number.isNaN(this.default.price)) this.default.price = 0;

    target.value = `${this.default.price}`;
  }

  private toFixed2(n: number): number {
    if (!n) return n;

    const value: number = Number.parseFloat(n.toString().match(/^(\d{1,5}\.\d{1,2})|^(\d{1,7})/)[0]);

    return Number.isNaN(value) ? 0 : value;
  }

  private initCurrentPriceDropdownText(): void {
    if (!this.currentPrice || !this.imageData || !this.imageData.prices) return;

    if (this.isNewPrice) {
      this.currentPriceOption = new SelectOption(OPTIONS_TEXTS.NEW_PRICE, null);

      return;
    }

    this.currentPriceOption = new SelectOption(this.getPriceDropdownText(this.currentPrice), `${this.currentPrice.id}`);
  }

  private initPrices(): void {
    if (!this.imageData || !this.imageData.prices || !this.measureUnits) return;

    this.pricesOptions = this.imageData.prices.map((price: ImagePriceModel) => {
      return new SelectOption(this.getPriceDropdownText(price), `${price.id}`);
    });

    this.isSinglePriceAndEmpty = this.pricesOptions.length === 1 && this.pricesOptions[0].label === OPTIONS_TEXTS.EMPTY_PRICE;

    if (!this.isMorePricesAvailable) return;

    this.pricesOptions.push(new SelectOption(OPTIONS_TEXTS.ADD_PRICE, OPTIONS_TEXTS.ADD_PRICE));
  }

  private getPriceDropdownText(imagePrice: ImagePriceModel): string {
    const price = imagePrice.isPriceDefault ? this.default.price : imagePrice.price;
    const width = imagePrice.isImageSizeDefault ? this.default.width : imagePrice.width;
    const height = imagePrice.isImageSizeDefault ? this.default.height : imagePrice.height;
    const maxWidth = imagePrice.isMatSizeDefault ? this.default.matwidth : imagePrice.matWidth;
    const matHeight = imagePrice.isMatSizeDefault ? this.default.matheight : imagePrice.matHeight;

    const formattedPrice = price && this.currencySymbol ? `${this.currencySymbol}${price.toLocaleString('en')}` : '';

    const size = width && height ? `${this.utilsService.formatImageDimension(width)} x ${this.utilsService.formatImageDimension(height)}${this.measureUnitSymbol}` : '';
    const matSize = maxWidth && matHeight ? `${this.utilsService.formatImageDimension(maxWidth)} x ${this.utilsService.formatImageDimension(matHeight)}${this.measureUnitSymbol} Mat` : '';

    const process = this.getProcess(imagePrice);

    const priceSizeString = `${formattedPrice}${formattedPrice && size ? ', ' : ''}${size}`;
    const matSizeString = `${priceSizeString}${priceSizeString && matSize ? ', ' : ''}${matSize}`;

    return `${matSizeString}${matSizeString && process ? ', ' : ''}${process}` || OPTIONS_TEXTS.EMPTY_PRICE;
  }

  private getProcess(imagePrice: ImagePriceModel): string {
    if (imagePrice.isProcessDefault) return this.default.process ? this.default.process : '';

    return imagePrice.process ? imagePrice.process.processName : '';
  }

  public toggleSimpleImageLabelForm(): void {
    if (this.isImageLabelTogglerBlocked || !this.isSimpleImageLabelTogglerVisible) return;

    this.isImageLabelTogglerBlocked = true;

    this.isSimpleImageLabel = !this.isSimpleImageLabel;

    const handler = () => {
      this.isImageLabelTogglerBlocked = false;
    };

    if (!this.simpleImageLabelToggleHandlers[this.activeImageManagerTab]) {
      return;
    }

    this.simpleImageLabelToggleHandlers[this.activeImageManagerTab](this.isSimpleImageLabel).subscribe(() => {
      if (!this.afterSimpleImageLabelToggleHandlers[this.activeImageManagerTab]) {
        return;
      }

      this.afterSimpleImageLabelToggleHandlers[this.activeImageManagerTab]().add(() => {
        handler();
      });
    }, handler);
  }

  private userWebsiteSimpleImageLabelToggle(): Observable<boolean> {
    return this.websitesHttpService.setIsSimpleImageLabel(this.isSimpleImageLabel);
  }

  private exhibitionWebsiteSimpleImageLabelToggle(): Observable<boolean> {
    return this.educationWebsitesService.setIsSimpleImageLabel(
      this.selectedExhibition.institutionId,
      this.selectedExhibition.classId,
      this.selectedExhibition.websiteId,
      this.isSimpleImageLabel,
    );
  }

  private afterUserWebsiteSimpleImageLabelToggle(): Subscription {
    return this.websitesService.fetchWebsite();
  }

  private afterExhibitionWebsiteSimpleImageLabelToggle(): Subscription {
    return this.educationWebsitesService.fetchOne(
      this.selectedExhibition.institutionId,
      this.selectedExhibition.classId,
      this.selectedExhibition.websiteId,
    );
  }

  public ngOnDestroy(): void {
    this.removeVisibleLocation(TOUR_KEY);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private addVisibleLocation(key: string): void {
    if (this.isInModal) return;

    this.websiteTourService.addVisibleLocation(key);
  }

  private removeVisibleLocation(key: string): void {
    if (this.isInModal) return;

    this.websiteTourService.removeVisibleLocation(key);
  }

  private addVisibleItem(key: string): void {
    if (this.isInModal) return;

    this.websiteTourService.addVisibleItem(key);
  }

  private removeVisibleItem(key: string): void {
    if (this.isInModal) return;

    this.websiteTourService.removeVisibleItem(key);
  }
}
