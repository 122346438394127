import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {NavigationService} from '../../../../../../../services/navigation.service';
import {ImagesCounterService} from '../../../../../../../core/services/image-manager/counters/images-counter.service';
import {ImageManagerService} from '../../../../../../main/image-manager/image-manager.service';
import {ImagesIconsService} from '../../../../../../../core/services/image-manager/icons/images-icons.service';
import {WebsitesService} from '../../../../../../../core/services/websites/websites.service';
import {WebsitesNodesService} from '../../../../../../../core/services/websites-nodes/websites-nodes.service';
import {EditorControlButtonsService} from '../../../../../../../services/editor-control-buttons.service';
import {CustomHomePageSetupModalService} from '../../../../../../../shared/services/modals/custom-home-page-setup/custom-home-page-setup-modal.service';
import {DirectLinkInputModalService} from '../../../../../../../shared/services/modals/direct-link-input/direct-link-input-modal.service';
import {DomainsService} from '../../../../../../../core/services/domains/domains.service';
import {WebsiteTourService} from '../../../../../../../core/services/website-tour/website-tour.service';
import {PageLockAlertModalService} from '../../../../../../../shared/services/modals/page-lock-alert/page-lock-alert-modal.service';

import {ImagesIconModel} from '../../../../../../../core/models/image-manager/icons/images-icon.model';
import {ImagesCounterModel} from '../../../../../../../core/models/image-manager/counters/images-counter.model';
import {DomainModel} from '../../../../../../../core/models/domain/domain.model';
import {WebsiteModel} from '../../../../../../../core/models/websites/website.model';
import { TreeItemModel } from '../../.././../../../../core/models/nodes/tree-item.model';

import {TOUR_KEY} from './constants';
import {LIBRARY_ID} from '../../../../../../constants';
import {KEYS} from '../../../../../../../core/services/website-tour/constants';

@Component({
  selector: 'app-basic-tree-node',
  templateUrl: './basic-tree-node.component.html',
  styleUrls: ['./basic-tree-node.component.scss'],
})
export class BasicTreeNodeComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('titleWrapper') titleWrapper: ElementRef;
  @ViewChild('editInput') editInput: ElementRef;
  
  @HostBinding('style.width') hostWidth: string = '100%';
  
  @Input() node: TreeItemModel;
  @Input() index: any;
  @Input() editNodeId: any;
  @Input() isSelected: boolean;
  @Input() isSplashEnabled: boolean = false;
  @Input() isSharePermitted: boolean = false;
  @Input() isNewReview: boolean = false;

  // Most of this methods are implemented in tree/node instance (todo: use them).
  @Output() doRemoveNode = new EventEmitter();
  @Output() portfolioCoverLoaded = new EventEmitter();
  @Output() portfolioCoverFailed = new EventEmitter();
  @Output() doSaveNode = new EventEmitter();
  @Output() doEditNode = new EventEmitter();
  @Output() cancelEditNode = new EventEmitter();
  @Output() shareToggleHandler = new EventEmitter();
  @Output() doTogglePageLock = new EventEmitter();
  @Output() doTogglePageVisibility = new EventEmitter();
  @Output() doTogglePageExistence = new EventEmitter();
  @Output() doAddDirectLink = new EventEmitter();
  @Output() doAddNode = new EventEmitter();
  @Output() doCloneNode = new EventEmitter();
  @Output() handleMouseOut = new EventEmitter();
  @Output() doDropdownVisibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public tooltipText: string = 'A splash screen is a first time only intro screen to your website. It does not appear in the menu system.';

  public isImagesMoving: boolean = false;
  public isImageManagerBlocked: boolean = false;
  public isImageManager: boolean = false;
  public isHovered: boolean = false;

  public isTitleOverflowing: boolean = false;
  public isHidePageOptionsVisible: boolean = false;
  public isLinkOptionsVisible: boolean = false;
  public isLinkCopied: boolean = false;
  public isCommandDropdownOnTop: boolean = false;

  public title: string = '';

  public iconPath: string = null;

  private website: WebsiteModel;

  private activeWebsiteId: number;

  private domain: DomainModel;

  private counters: ImagesCounterModel;
  private icons: ImagesIconModel;

  private copyLinkTimeoutId: number;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isNodeEdit(): boolean {
    return this.editNodeId === this.node.data.id;
  }

  public get LIBRARY_ID() {
    return LIBRARY_ID;
  }

  constructor(private navigationService: NavigationService,
              private cdr: ChangeDetectorRef,
              private imageManagerService: ImageManagerService,
              private imagesCounterService: ImagesCounterService,
              private imagesIconsService: ImagesIconsService,
              private websitesService: WebsitesService,
              private websitesNodesService: WebsitesNodesService,
              private customHomePageSetupModalService: CustomHomePageSetupModalService,
              private directLinkInputModalService: DirectLinkInputModalService,
              private websiteTourService: WebsiteTourService,
              private domainsService: DomainsService,
              private pageLockAlertModalService: PageLockAlertModalService,
              public editorControlButtonsService: EditorControlButtonsService) {
    this.imagesCounterService.countersSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((counters: ImagesCounterModel) => {
      this.counters = counters;

      this.initTitle();
    });

    this.navigationService.isImageManagerSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isImageManager = value;
    });

    this.imagesIconsService.iconsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((icons: ImagesIconModel) => {
      this.icons = icons;

      this.initIconPath();
    });
  }

  public ngOnInit(): void {
    this.websitesService.activeWebsiteIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: number) => {
      this.activeWebsiteId = value;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.imageManagerService.isImagesMovingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: { [key: number]: boolean }) => {
      this.isImagesMoving = !!value && Object.keys(value).some(key => value[key]);

      this.cdr.detectChanges();
    });

    this.imageManagerService.isPageBlockedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isImageManagerBlocked = value;

      this.cdr.detectChanges();
    });

    this.domainsService.primaryDomainSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((domain: DomainModel) => {
      this.domain = domain;
    });

    this.initIconPath();

    this.initTitle();
    this.initTour();

    this.websiteTourService.addVisibleLocation(TOUR_KEY);
  }

  public ngAfterViewChecked(): void {
    this.isTitleOverflowing = this.titleWrapper && this.titleWrapper.nativeElement.clientWidth < this.titleWrapper.nativeElement.scrollWidth;

    this.hostWidth = this.node && this.node.data.isHomePage ? '' : '100%';

    if (this.isNodeEdit && this.editInput && this.editInput.nativeElement && document.activeElement !== this.editInput.nativeElement) {
      this.editInput.nativeElement.focus();
    }
  }

  private initTitle(): void {
    if (!this.node || !this.node.data) return;

    if (this.node.data.type !== 'P') {
      this.title = this.node.data.title;

      return;
    }

    if (!this.counters) {
      this.title = this.node.data.title;

      return;
    }

    this.title = `${this.node.data.title} (${this.counters.getCount(this.node.data.id, this.isImageManager)})`;
  }

  private initTour(): void {
    if (this.isImageManager && this.node.data.tourKey === 'LIBRARY') {
      this.websiteTourService.addVisibleItem(KEYS.LIBRARY);

      return;
    }

    if (!this.isImageManager && this.node.data.tourKey === 'ACTIVE_PAGE') {
      this.websiteTourService.addVisibleItem(KEYS.ACTIVE_PAGE);

      return;
    }

    if (!this.isImageManager && this.node.data.tourKey === 'PAGE_TO_REMOVE') {
      this.websiteTourService.addVisibleItem(KEYS.PAGE_TO_REMOVE);

      return;
    }

    if (!this.isImageManager && this.node.data.tourKey === 'PORTFOLIO_PAGE') {
      this.websiteTourService.addVisibleItem(KEYS.PORTFOLIO_PAGE);

      return;
    }
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleLocation(TOUR_KEY);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private initIconPath() {
    if (!this.node || !this.node.data || this.node.data.type !== 'P') return;

    const trailer = this.icons[this.node.data.id];

    this.iconPath = trailer ? `https://${trailer}` : null;

    this.cdr.detectChanges();
  }

  editNode(event, node) {
    event.preventDefault();
    event.stopPropagation();
    node.data.newtitle = node.data.title;
    node.data.initialtitle = node.data.title;
    this.doEditNode.next(true);
  }

  onEditClick(event) {
    event.stopPropagation();
  }

  addNode(event, node) {
    event.preventDefault();
    event.stopPropagation();

    this.doAddNode.emit();
  }

  dropNode($event) {
    // const node = this.node.parent;
    // this.node.mouseAction('drop', $event.event, {
    //   from: $event.element,
    //   to: { parent: node, index: node.index }
    // });
  }

  allowDropOnMockup(element, $event) {
    return true;
  }

  public onMouseEnter(): void {
    this.isHovered = true;
  }

  public onMouseLeave(): void {
    if (this.isHidePageOptionsVisible || this.isLinkOptionsVisible) return;

    this.isHovered = false;
  }

  public onHidePageBackgroundClick(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();

    if (this.isHidePageOptionsVisible) this.handleMouseOut.emit();

    this.isHidePageOptionsVisible = false;

    this.notifyAboutDropdownsVisibility();
  }

  public onLinkBackgroundClick(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();

    if (this.isLinkOptionsVisible) this.handleMouseOut.emit();

    this.isLinkOptionsVisible = false;
    this.isLinkCopied = false;

    this.notifyAboutDropdownsVisibility();
  }

  public onHomeIconClick(e: MouseEvent): void {
    e.stopPropagation();

    if (this.customHomePageSetupModalService.isOriginalHomePageSetAsHome) {
      this.customHomePageSetupModalService.open(this.node);

      return;
    }

    this.websitesNodesService.updateHomePage({
      websiteId: this.activeWebsiteId,
      nodeId: this.node.data.nodeId,
    });
  }

  public togglePageLock(event, node): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.node.data.isPrivate || !this.website.privatePagePassword) {
      this.doTogglePageLock.next(!this.node.data.isPrivate);

      return;
    }

    this.pageLockAlertModalService.open({
      onConfirm: () => {
        this.doTogglePageLock.next(!this.node.data.isPrivate);
      },
    });
  }

  public clonePage(e, node): void {
    e.preventDefault();
    e.stopPropagation();

    this.doCloneNode.next(node);
  }

  public toggleIsPageExists(e): void {
    e.preventDefault();
    e.stopPropagation();

    this.doTogglePageExistence.next(true);
  }

  public toggleHidePageOptions(e: MouseEvent): void {
    const target: HTMLElement = <HTMLElement>e.target;

    if (target.tagName === 'A') return;

    e.preventDefault();
    e.stopPropagation();

    this.isHidePageOptionsVisible = !this.isHidePageOptionsVisible;

    this.notifyAboutDropdownsVisibility();

    if (!this.isHidePageOptionsVisible) return;

    this.initDropdownPosition(e);
  }

  public toggleLinkOptions(e: MouseEvent): void {
    const target: HTMLElement = <HTMLElement>e.target;

    if (target.tagName === 'A') return;

    e.preventDefault();
    e.stopPropagation();

    this.isLinkOptionsVisible = !this.isLinkOptionsVisible;

    this.notifyAboutDropdownsVisibility();

    if (!this.isLinkOptionsVisible) return;

    this.initDropdownPosition(e);
  }

  public initDropdownPosition(e: MouseEvent) {
    const target: HTMLElement = <HTMLElement>e.currentTarget;

    const dropdown: HTMLElement = <HTMLElement>target.querySelector('.command-dropdown');

    if (!dropdown) return;

    const distance: number = window.innerHeight - target.getBoundingClientRect().bottom;

    this.isCommandDropdownOnTop = distance < 240;
  }

  public toggleShare(e: MouseEvent, node): void {
    this.stopEvent(e);

    this.node.data.isShared = !this.node.data.isShared;
    this.node.data.isDesktopPageVisible = !this.node.data.isShared;
    this.node.data.isMobilePageVisible = !this.node.data.isShared;

    this.shareToggleHandler.emit();
  }

  public togglePageVisibility(e): void {
    this.stopEvent(e);

    this.node.data.isDesktopPageVisible = !this.node.data.isMobilePageVisible;
    this.node.data.isMobilePageVisible = this.node.data.isDesktopPageVisible;

    this.doTogglePageVisibility.next(true);
  }

  public toggleDesktopPageVisibility(e): void {
    this.stopEvent(e);

    this.node.data.isDesktopPageVisible = !this.node.data.isDesktopPageVisible && !this.node.data.isMobilePageVisible ? false : !this.node.data.isDesktopPageVisible;
    this.node.data.isMobilePageVisible = true;

    this.doTogglePageVisibility.next(true);
  }

  public copyPageLink(e: MouseEvent): void {
    this.stopEvent(e);

    if (!this.node.data.isPageExists) return;

    const link: string = this.getLink();

    if (!link) return;

    this.copy(e, link);

    this.isLinkCopied = true;

    window.clearTimeout(this.copyLinkTimeoutId);

    this.copyLinkTimeoutId = window.setTimeout(() => {
      this.isLinkCopied = false;
    }, 2000);
  }

  private getLink(): string {
    if (this.node.data.directLink) return this.node.data.directLink;

    return this.domain ? `https://${this.domain.name}/${this.node.data.nodeLink}` : null;
  }

  public copy(e: MouseEvent, str: string): void {
    const target = <HTMLElement>e.currentTarget;

    target.classList.add('copied');

    const input = document.createElement('input');

    document.body.appendChild(input);

    input.value = str;

    input.select();

    document.execCommand('copy');

    input.remove();

    setTimeout(() => target.classList.remove('copied'), 500);
  }

  public addDirectLink(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();

    this.directLinkInputModalService.onSaveSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { websiteId: number, nodeId: number, link: string, isInNewTab: boolean }) => {
      if (!data || !this.node || data.nodeId !== this.node.data.nodeId) return;

      this.node.data.directLink = data.link;
      this.node.data.directLinkInNewTab = data.isInNewTab;
    });

    this.doAddDirectLink.next(true);
  }

  public toggleExpanded(e, node: TreeItemModel): void {
    e.preventDefault();
    e.stopPropagation();

    node.expanded = !node.expanded;
  }

  public setTooltipPosition(event): void {
    const tooltip = event.target.querySelector('.command-tooltip');

    if (!tooltip) return;

    const { top, left } = event.target.getBoundingClientRect();

    tooltip.style.top = `${top}px`;
    tooltip.style.left = `${left + event.target.clientWidth / 2 - tooltip.clientWidth / 2}px`;
  }

  public setDropdownTooltipPosition(event): void {
    const tooltip = event.target.querySelector('.command-dropdown-tooltip');

    if (!tooltip) return;

    const { top, left } = event.target.getBoundingClientRect();

    tooltip.style.top = `${top}px`;
    tooltip.style.left = `${left + event.target.clientWidth / 2 - tooltip.clientWidth / 2}px`;
  }

  public toggleSplash(event) {
    event.stopPropagation();

    this.editorControlButtonsService.onBtnClick(this.editorControlButtonsService.CONTROL_BUTTONS.TOGGLE_SPLASH_PAGE, !this.isSplashEnabled);
  }

  public stopPropagation(e: MouseEvent): void {
    e.stopPropagation();
  }

  public stopEvent(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  private notifyAboutDropdownsVisibility(): void {
    this.doDropdownVisibilityChange.emit(this.isHidePageOptionsVisible || this.isLinkOptionsVisible);
  }
}
